<template>
  <default-layout>
    <Menu :color="(scrolled)? 'scrolledClass':'black'"/>
    <div v-if="this.$route.params.id != 3" class="blende__Block"></div>
    <brands-description v-if="this.$route.params.id == 3"
                        :descriptionObject="alcohols.filter(object => object.id == this.$route.params.id)[0]">
    </brands-description>
    <div v-if="alcohols.length">
      <brands-content
          :heroImageDesktop="alcohols.filter(object => object.id == this.$route.params.id)[0]"
      ></brands-content>
    </div>
    <div v-else class="else__loading"><img class="loading__gif" src="../../public/loading-gif.gif" alt=""></div>
  </default-layout>
</template>

<script>
import BrandsDescription from "@/components/brands/BrandsDescription";
import BrandsContent from "@/components/brands/BrandsContent";
import Menu from "../components/Menu";
import axios from "axios";

export default {
  name: "Brands",
  data() {
    return {
      scrolled: false,
      alcohols: []
    }
  },
  props: ['id'],
  components: {
    Menu,
    BrandsDescription,
    BrandsContent,
  },
  mounted() {
    axios.get('/api/alcohol-types').then(re => (this.alcohols = re.data.types))
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrolled = window.scrollY > 100;
    }
  }
};
</script>

<style lang="scss">
@import "src/sass/variables";

.blende__Block {
  height: 175px;
  background: #242526;
}

.else__loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  img {
    width: 200px;
    height: 150px;
  }
}

@media screen and (max-width: 990px) {
  .blende__Block {
    display: none;
  }
}
</style>