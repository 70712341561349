<template>
  <div class="navigation__container">
    <nav :class="color">
      <div class="wrapper">
        <div class="logo">
          <a href="/">
            <img srcset="/images/KWS.svg,
             /images/KWS-2x.svg 2x"
                 src="/images/KWS.svg" class="menu_logo" alt=""></a>
        </div>
        <input type="radio" name="slider" id="menu-btn">
        <input type="radio" name="slider" id="close-btn">
        <label for="close-btn" class="btn close-btn"><img src="../../public/images/close-btn.svg" alt=""></label>

        <ul class="nav-links">
          <!-------------------------------------------------------------------------------------------------------------------->
          <li v-for="router in routers" :key="router.id"
              v-on:mouseover="mouseOver(router.id)"
              v-on:mouseleave="mouseLeave(router.id)"
              class="mobile_li_style">
            <a :href="router.link" :id="'link' + router.id" class="desktop-item">{{ router.name }}</a>
            <input type="checkbox" :id="`showMega${router.id}`">
            <label :for="`showMega${router.id}`" class="mobile-item"><a :href="router.link"
                                                                        v-if="router.children.length == false">{{
                router.name
              }}</a>
              <span v-if="router.children.length">{{
                  router.name
                }}</span>
            </label>
            <div v-if="router.children.length" class="mega-box">
              <div class="content">
                <div class="row">
                  <ul class="mega-links">
                    <li v-for="child in router.children" :key="child.id">
                      <a :href="child.link">{{ child.name }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>

          <li class="mobile_li_style unset__pseudo" v-on:mouseover="mouseOver('lang')"
              v-on:mouseleave="mouseLeave('lang')">
            <a href="#" id="languageHref" class="desktop-item action__onHover">{{ currentLang == 'kk' ? 'kz' : currentLang }}
              <img src="../../public/images/arrow-down.svg" alt="" class="lang__arrow">
            </a>
            <a href="#" @click="changeLang('ru')"
               :class="(currentLang == 'ru')?'active__lang hidden__inDesktop':'hidden__inDesktop'">{{
                langRu.visible_name
              }}</a>
            <a href="#" @click="changeLang('en')"
               :class="(currentLang == 'en')?'active__lang hidden__inDesktop':'hidden__inDesktop'">{{
                langEn.visible_name
              }}</a>
            <a href="#" @click="changeLang('kk')"
               :class="(currentLang == 'kk')?'active__lang hidden__inDesktop':'hidden__inDesktop'">{{
                langKk.visible_name
              }}</a>

            <div class="hidden__inMobile">
              <input type="checkbox" id="showMega4">
              <label for="showMega4" class="mobile-item">{{ langRu.visible_name }}</label>
              <div class="mega-box">
                <div class="content">
                  <div class="row">
                    <ul class="mega-links">
                      <li>
                        <input type="radio" name="language" id="russia">
                        <label v-model="lang" @click="changeLang('ru')" for="russia">{{ langRu.value }}</label>
                      </li>
                      <li>
                        <input type="radio" name="language" id="english">
                        <label v-model="lang" @click="changeLang('en')" for="english">{{ langEn.value }}</label>
                      </li>
                      <li>
                        <input type="radio" name="language" id="kazakh">
                        <label v-model="lang" @click="changeLang('kk')" for="kazakh">{{ langKk.value }}</label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="background__li" id="background__li">
          </li>
          <!------------------------------------------------------------------------>
        </ul>

        <label for="menu-btn" class="btn menu-btn"><i class="fas fa-bars"></i></label>
      </div>
    </nav>
    <div class="relative__container">

    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Menu",
  data() {
    return {
      routers: null,
      lang: localStorage.getItem('lang'),
      langRu: '',
      langEn: '',
      langKk: '',
      currentLang: localStorage.getItem('lang'),
      windowWidth: window.innerWidth
    }
  },
  props: {
    color: String,
  },
  methods: {
    changeLang(lang) {
      localStorage.setItem('lang', lang);
      window.location.reload()
    },
    mouseOver(routerId) {
      try {
        if (routerId === 'lang') {
          let langUrl = document.getElementById('languageHref')
          langUrl.style.color = '#7F7F7F'
          langUrl.style.borderBottom = '1px solid white'
          let background__li = document.getElementById('background__li')
          background__li.classList.add('background__li__hovered')
          background__li.style.display = 'block'
        } else {
          let url = document.getElementById('link' + routerId)
          url.style.color = '#7F7F7F'
          url.style.borderBottom = '1px solid white'
          if (this.routers.filter(router => (router.id === routerId))[0].children.length) {
            let background__li = document.getElementById('background__li')
            background__li.classList.add('background__li__hovered')
            background__li.style.display = 'block'
          }
          if (this.windowWidth < 990) {
            document.getElementById('background__li').remove()
          }
        }
      } catch (error) {
      }
    },
    mouseLeave(routerId) {
      try {
        if (routerId === 'lang') {
          let langUrl = document.getElementById('languageHref')
          langUrl.style.color = '#FFFFFF'
          langUrl.style.borderBottom = 'initial'
          let background__li = document.getElementById('background__li')
          background__li.classList.remove('background__li__hovered')
          background__li.style.display = 'none'
        } else {
          let url = document.getElementById('link' + routerId)
          url.style.color = '#FFFFFF'
          url.style.borderBottom = 'initial'
          let background__li = document.getElementById('background__li')
          background__li.classList.remove('background__li__hovered')
          background__li.style.display = 'none'
        }
      } catch (error) {
      }
    }
  },
  mounted() {
    axios.get('/api/categories')
        .then(response => (this.routers = response.data.categories))
    axios.get('/api/settings/main.ru').then(response => (this.langRu = response.data.setting))
    axios.get('/api/settings/main.en').then(response => (this.langEn = response.data.setting))
    axios.get('/api/settings/main.kk').then(response => (this.langKk = response.data.setting))
  }
};
</script>

<style lang="scss">
@import "src/sass/variables";

#russia, #english {
  width: 0;
  height: 0;
}

* {
  font-family: $font-primary;
}

.unset__pseudo:hover .background__li__hovered {
  display: block !important;
}

.scrolledClass {
  background: #242526;
  position: fixed !important;
  z-index: 100;
  left: 0;
  top: 0;

  .wrapper {
    padding: 0;
    flex-direction: row;
    height: 64px;

    .logo {
      position: absolute;
      z-index: 100;
      left: 0;
      height: max-content;
      padding-left: 5px;
    }

    .menu_logo {
      width: 76px;
      height: 24px
    }

    .nav-links {
      height: 100%;
      justify-content: center;
      margin-bottom: 0;
    }
  }
}

.links__list {
  display: flex;
  flex-direction: row;
}

.notScrolled {
  background: transparent;
  position: fixed;
}

.black {
  background: #242526;
}

.lang__arrow {
  width: 15px;
  height: 10px;
  filter: brightness(1);
}

.action__onHover:hover > .lang__arrow {
  filter: brightness(0.7);
}

nav {
  position: absolute;
  top: 0;
  z-index: 99;
  width: 100%;
  transition: 0.5s;
}

nav:hover {
  background: #242526
}

.row {
  display: block;
  margin: 0;

  .mega-links {
    margin: 0;
    padding: 0;

    li {
      margin: 0;
    }
  }
}

nav .wrapper {
  position: relative;
  max-width: 1300px;
  padding: 48px 30px 20px 30px;
  height: 175px;
  line-height: 70px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}

.wrapper .logo a {
  color: #f2f2f2;
  font-size: 30px;
  font-weight: 600;
  text-decoration: none;
}

.hidden__inDesktop {
  display: none;
}

.relative__container {
  display: none;
  width: 100%;
  height: 175px;
}

.wrapper .nav-links {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1280px;
  width: 100%;
}

.menu_logo {
  width: 100px;
  height: 32px;
}

.nav-links li {
  position: relative;
  list-style: none;
  margin: 0 40px;
}

.nav-links li a {
  color: #ffffff;
  text-decoration: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  padding: 9px 0;
  transition: all 0.3s ease;


}



.desktop-item, .mobile-item {
  text-transform: uppercase;
}

.desktop-item:hover {
  color: #7F7F7F;
  border-bottom: 1px solid white;
}

.nav-links .mobile-item {
  display: none;
}

.nav-links li:hover .mega-box {
  transition: all 0.5s ease !important;
  top: 60px;
  opacity: 1;
  visibility: visible;
}

.background__li__hovered {
  transition: all 0.5s ease !important;
  top: 60px !important;
  opacity: 1 !important;
  visibility: visible !important;
}





.background__li {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  position: absolute !important;
  height: 206px;
  display: none;
  background: #242526;
  left: 0;
  width: 100%;
  top: 85px;
  opacity: 0;
  visibility: visible;
}

.mega-box {
  position: absolute;
  width: max-content;
  left: 0;
  z-index: 200;
  top: 85px;
  opacity: 0;
  visibility: hidden;
}


.close-btn img {
  height: 20px;
  width: 20px;
}

.wrapper .btn.close-btn {
  position: fixed !important;

  display: none;
  left: 365px;
  top: -4px;
}

.btn.menu-btn:checked ~ .close-btn {
  display: block;
}

.mega-box .content {
  padding: 25px 0;
}

.mega-box .content .row {
  line-height: 45px;
}

.content .row img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.row .mega-links li {
  border: none;
  width: max-content;
}

.close-btn {
  width: 100%;
  height: 100%;
  position: relative;

  img {
    position: absolute;
    top: 20px;
    left: 20px;
  }
}

.row .mega-links li a, .row .mega-links li label {
  padding: 0;
  color: #ffffff;
  font-size: 16px;
  text-transform: uppercase;
  display: block;
  cursor: pointer
}

.row .mega-links li a:hover, .row .mega-links li label:hover {
  color: #7F7F7F;
}

.wrapper .btn {
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  display: none;
}

.display-none-li {
  display: none;
}

.hidden__inMobile {
  display: block;
}

@media screen and (max-width: 990px) {
  .navigation__container {
    height: 64px !important;
  }
  nav .wrapper {
    height: 64px !important;
  }
  .background__li__hovered {
    display: none !important;
    opacity: 0;
  }
  .scrolledClass {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 0;

    .wrapper {
      height: 64px !important;

      .logo {
        position: relative;
        z-index: 1000;
        padding-left: 0;
      }

      .menu_logo {
        width: 64px;
        height: 20px
      }
    }
  }
  .relative__container {
    display: block !important;
    height: 75px;
  }
  nav .wrapper {
    top: 0;
    position: fixed !important;

    width: 100%;
    height: 175px;
    background: #242526;
  }
  .display-none-li {
    display: block;
  }
  .links__list {
    max-width: 350px;
    width: 73%;
    flex-direction: column;

  }
  .hidden__inDesktop {
    display: block;
  }
  .active__lang {
    position: relative;
    //border: 1px solid red;
  }
  .active__lang:after {
    content: '';
    position: absolute;
    bottom: 15px;
    left: 0;
    width: 25px;
    height: 1px;
    background: white;
  }
  .unset__pseudo:before {
    content: '';
    display: none;
  }
  .unset__pseudo:after {
    content: '';
    display: none;
  }
  .hidden__inMobile {
    display: none;
  }
  .wrapper .btn {
    display: block;
    margin-top: 15px;
  }
  .menu_logo {
    width: 64px;
    height: 20px;
  }
  .wrapper {
    padding: 0 16px 0 16px !important;
    flex-direction: row-reverse !important;
    align-items: center !important;
    height: max-content !important;
    justify-content: unset !important;
  }

  .logo {
    margin: 0 auto;
  }
  .wrapper .logo a {
    margin-left: -50px;
  }
  .wrapper .nav-links {
    position: fixed !important;

    height: 100vh;
    width: 73%;
    max-width: 350px;
    top: 0;
    left: -360px;
    background: #242526;
    display: block;
    padding: 50px 10px;
    line-height: 50px;
    overflow-y: auto;
    box-shadow: 0 15px 15px rgba(0, 0, 0, 0.18);
    transition: all 0.3s ease;
  }

  .mobile_li_style {
    position: relative;
  }
  .mobile_li_style:after, .mobile_li_style:before {
    display: none;
  }
  .mega-box:before {
    content: '';
    width: 10px !important;
    height: 1px;
    position: absolute;
    top: 25px;
    right: 0;
    transform: translateY(-50%);
    background-color: white;
  }
  .mega-box:after {
    content: '';
    width: 1px;
    height: 10px !important;
    position: absolute;
    top: 25px;
    right: 5px !important;
    transform: translateY(-50%);
    background-color: white;
    transition: 0.5s;
  }
  #showMega1:checked ~ .mega-box:after,
  #showMega2:checked ~ .mega-box:after,
  #showMega3:checked ~ .mega-box:after,
  #showMega4:checked ~ .mega-box:after,
  #showMega5:checked ~ .mega-box:after,
  #showMega6:checked ~ .mega-box:after {
    transform: rotate(-90deg);
    right: 20px;
    top: 20px;
  }
  .mega-links li a {
    color: rgb(255, 255, 255, .8) !important;
  }
  /* custom scroll bar */
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #242526;
  }
  ::-webkit-scrollbar-thumb {
    background: #3A3B3C;
  }


  #menu-btn:checked ~ .nav-links {
    //overflow: hidden;
    left: 0;

    &::-webkit-scrollbar-thumb {
      background: none;
    }

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  #menu-btn:checked ~ .btn.menu-btn {
    opacity: 0;
    visibility: hidden;
  }
  #menu-btn:checked ~ .btn.close-btn {
    display: block !important;
  }
  #close-btn:checked ~ .btn.menu-btn {
    display: block;
  }

  .nav-links li {
    margin: 15px 10px;
  }

  .nav-links li a {
    padding: 0 0px;
    display: block;
    font-size: 16px;
  }

  #showMega1:checked ~ .mega-box,
  #showMega2:checked ~ .mega-box,
  #showMega3:checked ~ .mega-box,
  #showMega4:checked ~ .mega-box,
  #showMega5:checked ~ .mega-box,
  #showMega6:checked ~ .mega-box,
  #showMega7:checked ~ .mega-box,
  #showMega8:checked ~ .mega-box, {
    max-height: 100%;
    transition: all 0.3s ease !important;
  }

  .nav-links .desktop-item {
    display: none;
  }

  .nav-links .mobile-item {
    display: block;
    color: #f2f2f2;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    border-bottom: 1px solid rgba(255, 255, 255, 0.16);
  }

  .borderWhite {
    border-bottom: 1px solid rgba(255, 255, 255, 0.16);
  }

  .nav-links .mobile-item:hover {
    background: #3A3B3C;
  }

  .mega-box {
    position: static;
    top: 65px;
    opacity: 1;
    visibility: visible;
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease !important;
  }

  .mega-box .content {
    box-shadow: none;
    flex-direction: column;
    padding: 5px 0;
  }

  .mega-box .content .row {
    width: 100%;
    margin-bottom: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.08);
  }

  .mega-box .content .row:nth-child(1),
  .mega-box .content .row:nth-child(2) {
    border-top: 0;
  }


  .row .mega-links li {
    margin: 0 0 8px 30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.16);
    width: 100%;
  }

  .row .mega-links li a {
    padding: 0;
  }
}

@media screen and (max-width: 425px) {
  .row .mega-links li {
    margin-left: 15px;
  }
}

@media screen and (max-width: 417px) {
  .wrapper .btn.close-btn {
    left: 75%;
  }
}

@media screen and (max-width: 375px) {
  .wrapper .btn.close-btn {
    left: 85%;
  }
  .wrapper .nav-links {
    width: 84%;
  }
  .row .mega-links li {
    margin-left: 15px
  }
}

@media screen and (width: 768px) {
  //.wrapper .nav-links {
  //  max-width: 275px !important;
  //}
  .wrapper .close-btn {
    left: 280px !important;
  }
  .background__li__hovered {
    display: block;
  }
}

@media screen and (width: 540px) {
  .wrapper .nav-links {
    max-width: 340px !important;
  }
  .wrapper .close-btn {
    left: 343px !important;
  }
}

nav input {
  display: none;
}
</style>
