<template>
  <div class="template_div">
    <main>
      <slot></slot>
    </main>
    <the-footer/>
  </div>
</template>

<script>
import TheFooter from "@/components/TheFooter"

export default {
  name: "DefaultLayout",
  components: {
    TheFooter,
  }
}
</script>
<style>
.template_div {
  min-height: 102vh;
  display: flex;
  flex-direction: column;
}
main{
  /*background: #FBFBFB;*/
  position: relative;
  background: white;
  flex: 1 1 auto;
}
</style>