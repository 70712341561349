<template>
  <default-layout>
    <Menu :color="(scrolled)? 'scrolledClass':'black'"/>
    <services-hero :heroImage="heroImage" :heroText="heroText"></services-hero>
    <component :is="dynamicServicesContent"></component>
  </default-layout>
</template>

<script>
import ServicesHero from "@/components/services/ServicesHero"
import ServicesContentMain from "@/components/services/ServicesContentMain"
import ServicesContentRetail from "@/components/services/ServicesContentRetail"
import ServicesContentClients from "@/components/services/ServicesContentClients"
import Menu from "../components/Menu";

export default {
  name: "Services",
  props: ['id'],
  data() {
    return {
      scrolled: false,
    }
  },
  components: {
    Menu,
    ServicesHero,
    ServicesContentMain,
    ServicesContentRetail,
    ServicesContentClients,
  },
  computed: {
    dynamicServicesContent: function () {
      if (this.id === '1')
        return "services-content-main"
      else if (this.id === '2')
        return "services-content-retail"
      else if (this.id === '3')
        return "services-content-clients"
    },
    heroImage: function () {
      if (this.id === '1')
        return "/images/services/horeca__banner.png"
      else if (this.id === '2')
        return "/images/services/retail__banner.png"
      else if (this.id === '3')
        return "/images/services/clients__banner.png"
    },
    heroText: function () {
      if (this.id === '1')
        return "horeca"
      else if (this.id === '2')
        return "retail"
      else if (this.id === '3')
        return "корпоративным клиентам"
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrolled = window.scrollY > 100;
    }
  }
}
</script>

<style lang="scss">

.services__content {
  overflow: hidden;
  max-width: 1600px;
  margin: 0 auto;
}

</style>