<template>
  <default-layout v-if="info.length">
    <Menu :color="(scrolled)? 'scrolledClass':'black'"/>
    <div class="header__banner">
      <div class="banner__bg">
        <div class="bg__content">
          <div class="about-our-employers">{{ settings[0].value }}</div>
          <div class="line"></div>
          <p class="content">{{ settings[1].value }}</p>
        </div>
      </div>
    </div>
    <div class="limit__founders__container">
      <div class="founders-container">
        <div class="seo-top-white-block">
        </div>
        <div class="seo">
          <div class="founder-box">
            <div class="box-img" :style="{backgroundImage: `url('${info[0].img}')`}"></div>
            <h2>{{ info[0].name }}</h2>
            <div class="line"></div>
            <span>{{ info[0].position }}</span>
          </div>
        </div>
      </div>
      <div class="other-founder-container">
        <div class="founder-box" v-for="founder in Number(info.length - 1)">
          <div class="box-img" :style="{backgroundImage: `url('${info[founder].img}')`}"></div>
          <h2>{{ info[founder].name }}</h2>
          <div class="line"></div>
          <span>{{ info[founder].position }}</span>
        </div>
      </div>
    </div>
  </default-layout>
</template>

<script>
import Menu from "../components/Menu";
import axios from "axios";
export default {
  name: "Founders",
  components: {
    Menu: Menu,
  },
  data() {
    return {
      scrolled: false,
      info: {},
      settings: [
        {}, {}
      ],
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrolled = window.scrollY > 100;
    }
  },
  async mounted() {
    axios.get('/api/founders').then(response => (this.info = response.data.sections))
    axios.get('/api/settings/founders.title').then(response => (this.settings[0] = response.data.setting))
    axios.get('/api/settings/about.text').then(response => (this.settings[1] = response.data.setting))
  }
}
</script>

<style scoped lang="scss">
@import "../sass/variables";
.bg__content p, .founder-box span{
  font-family: $font-secondary;
}
.banner__bg {
  background: #EFE8DE;
  height: 436px;
  text-align: center;
}


.banner__bg .about-our-employers {
  display: inline-block;
  padding: 0 30px;
  font-weight: 250;
  font-family: $font-primary;;
  font-size: 30px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #010101;
}

.banner__bg .line {
  margin-top: 30px;
  width: 160px;
}

.banner__bg p {
  margin: 20px 0 0 0;
  display: inline-block;
  text-align: center;
  font-family: $font-primary;;
  font-weight: 200;
  font-size: 24px;
  line-height: 34px;
  color: #010101;
}

.limit__founders__container {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.banner__bg .about-our-employers {
  display: inline-block;
  font-weight: 250;
  font-size: 30px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #010101;
}

.bg__content {
  padding-top: 112px;
}

.header__banner {
  background: #EFE8DE;
  padding-top: 173px;
}

.banner__bg .line {
  margin-top: 30px;
  width: 160px;
}

.banner__bg p {
  font-family: $font-primary;;
  margin: 20px 20px 0 20px;
  max-width: 582px;
  height: 68px;
  text-align: center;
  font-weight: 275;
  font-size: 24px;
  line-height: 34px;
  color: #010101;
}

.seo-top-white-block {
  width: 100%;
  z-index: 10;
  background: white;
  padding-top: 54px;
  padding-bottom: 50px;
  position: relative;
  margin-top: -84px;
  text-align: center;
}

.seo {
  display: flex;
  justify-content: center;
}

.founder-box {
  margin: 26px;
  display: inline-block;
  text-align: center;
  padding: 5px;
}

.box-img {
  //background: url("../../public/images/founders/ceo-director.jpg") no-repeat center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 320px;
  height: 320px
}

.line {
  width: 80px;
  height: 1px;
  margin: 5px auto;
  background: #010101;
}

h2 {
  padding-bottom: 12px;
  font-family: $font-primary;;
  margin-top: 24px;
  font-weight: 250;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #010101;
}

span {
  margin-top: 8px;
  font-weight: 300;
  font-size: 16px;
  line-height: 26px;
  font-family: $font-primary;;
  text-align: center;
  color: #999999;
}

.other-founder-container {
  margin-bottom: 120px;
  margin-left: auto;
  margin-right: auto;
  width: 1146px;
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 1600px) {
  .founders-container {
    margin: 0 6%;
  }
}

@media screen and (max-width: 990px) {
  .header__banner {
    padding-top: 0;
  }
}

@media screen and (max-width: 1145px) {
  .other-founder-container {
    width: 764px !important;
  }
}

@media screen and (max-width: 763px) {
  .other-founder-container {
    width: 375px !important;
  }
}

@media screen and (max-width: 610px) {
  .about-our-employers {
    font-size: 16px !important;
  }
  .banner__bg p {
    font-size: 16px;
  }
}

@media screen and (max-width: 470px) {
  .founders-container {
    margin: 0 24px;
  }
}

@media screen and (max-width: 360px) {
  .box-img {
    width: 260px;
    height: 260px;
  }
  .other-founder-container {
    width: 260px !important;
  }
  .founder-box {
    margin-left: 0;
    margin-right: 0;
  }
  .founder-box {
    h2 {
      font-size: 14px;
    }
  }
  .founder-box {
    padding: 0;
  }
  .other-founder-container {
    margin-bottom: 100px;
    margin-right: auto;
    margin-left: auto;
  }
}

</style>