<template>
  <default-layout>
    <Menu :color="(scrolled)? 'scrolledClass':'black'"/>
    <div class="limit__contact__container">
      <div v-if="responseData.length" class="follow-us-block">
        <h1>{{ responseData[36].value }}</h1>
        <div class="follow-us-icon">
          <div>{{ responseData[35].value }}</div>
          <div>
            <a :href="facebookUrl" class="social-media-links"><img src="../../public/images/contact/contacts__facebook.svg" alt=""></a>
            <a :href="instagramUrl" class="social-media-links"><img src="../../public/images/contact/contacts__inst.svg" alt=""></a>
          </div>
        </div>
        <div class="detail-contact-block">
          <div class="contact-block-item">
            <img src="../../public/images/contact/mail__kws.svg" alt="">
            <div class="block-border-line"></div>
            <div class="item-content">
              <h3>{{ responseData[13].value }}</h3>
              <span>
              <a href="mailto:welcome@kws.kz">{{ responseData[14].value }}</a>
              </span>
            </div>
            <div class="item-content">
              <h3>Для предложений</h3>
              <span><a href="mailto:info@brut.kz">{{ responseData[16].value }}</a></span>
            </div>
          </div>
          <div class="contact-block-item">
            <img src="../../public/images/contact/call__kws.svg" alt="">
            <div class="block-border-line"></div>
            <div class="item-content">
              <h3>{{ responseData[15].value }}</h3>
              <span><a :href="`tel:${responseData[17].value}`">{{ responseData[17].value }}</a></span>
            </div>
            <div class="item-content">
              <h3>Для предложений</h3>
              <span>
                <a :href="`tel:${responseData[18].value}`">{{ responseData[18].value }}</a>
              </span>
            </div>
          </div>
          <div class="contact-block-item">
            <img src="../../public/images/contact/location__kws.svg" alt="">
            <div class="block-border-line"></div>
            <div class="item-content">
              <h3>{{ responseData[19].value }}</h3>
              <span>{{ responseData[21].value }}</span>
            </div>
            <div class="item-content">
              <h3>{{ responseData[20].value }}</h3>
              <span>{{ responseData[22].value }}</span>
            </div>
          </div>
        </div>
        <div class="contact-img"
             :style="{backgroundImage: `url('https://pma.kws.kz${responseData[24].value}')`}"></div>
        <div class="rectangle__limit">
          <div class="contact-bg-rec">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  </default-layout>
</template>

<script>
import Menu from "../components/Menu";
import axios from "axios";
import base__url from "@/config";

export default {
  name: "Contact",
  components: {
    Menu: Menu,
  },
  data() {
    return {
      scrolled: false,
      responseData: [],
      facebookUrl: '',
      instagramUrl: ''
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    axios.get('/api/settings').then(re => (this.responseData = re.data.settings))
    axios.get('/api/settings/facebook').then(re => this.facebookUrl = re.data.setting.value)
    axios.get('/api/settings/instagram').then(re => this.instagramUrl = re.data.setting.value)
  },
  methods: {
    handleScroll() {
      this.scrolled = window.scrollY > 100;
    }
  }
}
</script>

<style scoped lang="scss">

.limit__contact__container {
  max-width: 1440px;
  padding-top: 170px;
  margin: 0 auto;
}

.social-media-links {
  color: black;
}

.follow-us-block {
  padding: 0 8px;
  box-sizing: border-box;
  text-align: center;
  margin-top: 80px;
  margin-bottom: 215px;
}

.follow-us-block h1 {
  margin: 0 0 39px 0;
  position: relative;
  padding-bottom: 40px;
  font-weight: 250;
  font-size: 30px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #010101;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 240px;
    height: 1px;
    background: #010101;
  }
}

.follow-us-icon div {
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.12em;
  color: #010101;
  display: inline-block;
  margin: 10px 5px;
}

.follow-us-icon div img {
  margin: 0 5px;
  width: 24px;
  height: 24px;
}

.detail-contact-block {
  flex-direction: row;
  justify-content: space-around;
  margin: 5.6%;
  display: flex;
  flex-wrap: wrap;
}

.contact-block-item {
  position: relative;
  text-align: left;
  padding: 10px 55px;
}

.block-border-line {
  position: absolute;
  height: 151px;
  width: 1px;
  background: black;
  bottom: 70px;
  left: 0;
}

.item-content {
  margin-bottom: 60px;
}

.detail-contact-block h3 {
  font-weight: bold;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #010101;
}
.detail-contact-block span, .detail-contact-block span a {
  text-decoration: none;
  font-weight: 300;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #010101;
}

.contact-block-item img {
  position: absolute;
  top: 0;
  left: -10px;
}

.contact-img {
  //background: url("../../public/images/contact/contact-footer-img.jpg") no-repeat center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 1280px;
  margin: 20px auto;
  height: 320px;
}

.rectangle__limit {
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  max-width: 1280px;
  width: 100%;
  height: max-content;
}

.contact-bg-rec {
  box-sizing: border-box;
  position: relative;
  margin: 25px 0;
  width: 47%;
  height: 64px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.contact-bg-rec div {
  background: #CCAD7B;
  height: 100%;
}

.contact-bg-rec div:nth-child(1) {
  width: 65.6%;
}

.contact-bg-rec div:nth-child(2) {
  width: 9.2%;
}

.contact-bg-rec div:nth-child(3) {
  width: 6.6%;
}

.contact-bg-rec div:nth-child(4) {
  width: 5.2%;
}

.contact-bg-rec div:nth-child(5) {
  width: 2.6%;
}

@media screen and (max-width: 970px) {
  .limit__contact__container {
    padding-top: 0 !important;
  }

  .contact-img {
    height: 160px;
  }

  .contact-bg-rec {
    flex-direction: row-reverse;
    width: 100%;
    height: 40px;
    margin: 20px 0 20px 0;
  }

  .detail-contact-block {
    padding: 0 8px;
  }

  .follow-us-block {
    padding: 0;
  }

  .contact-img {
    margin-left: 8px;
    margin-right: 8px;
  }
}

@media screen and (max-width: 825px) {
  .contact-block-item {
    max-width: 378px;
    width: 100%;
    margin: 0 auto;
  }

  .follow-us-block {
    margin-bottom: 70px;
  }
}

@media screen and (max-width: 816px) {
  .detail-contact-block {
    flex-direction: column;
    margin: 20px 16px;
  }
}

@media screen and (max-width: 522px) {
  .detail-contact-block span {
    font-size: 16px;
  }

  .follow-us-block h1 {
    padding-bottom: 16px;
    position: relative;
    font-weight: 300;
    font-size: 16px;
    line-height: 16px;
  }

  .contact-block-item {
    padding: 10px 5px;
  }

  .follow-us-block h1:after {
    content: '';
    width: 100px;
    height: 1px;
    background: black;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .follow-us-icon {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 63px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }

  .contact-block-item {
    padding-top: 42px;
    text-align: center;
  }

  .block-border-line {
    width: 100%;
    height: 1px;
    top: 0;
    left: 0;
  }

  .contact-block-item img {
    background: #FBFBFB;
    z-index: 2;
    padding: 16px;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
  }

  .item-content {
    margin-bottom: 32px;
  }
}

@media screen and (max-width: 320px) {
  .item-content span, .item-content h3 {
    font-size: 15px;
  }

}
</style>