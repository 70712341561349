<template>
  <default-layout>
    <Menu :color="(scrolled)? 'scrolledClass':'black'"/>
    <div class="event-page-component">
      <div class="event_title">
        {{event.title}}
      </div>
      <div class="flexable__ladyG_container">
        <div class="container__flex__item">
          <div class="orangeBg"></div>
          <div class="ladyBlock">
            <div class="ladyBgImg" :style="{backgroundImage: `url('${event.img_inner}')`}"></div>
            <div class="lady-bg-rec">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div class="ladyParagraphs" v-html="event.main_text">

            </div>
          </div>
        </div>
        <div class="container__flex__item">
          <div class="flexItem__limit">
            <p class="flex__iladyParagraphstem is-color-light">
              {{event.subtitle}}
            </p>
            <div class="lady-bg-rec">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <p class="flex__iladyParagraphstem">
              {{event.secondary_text}}
            </p>
          </div>
          <div class="wine-parent-b">
            <div class="wineBgImg" :style="{backgroundImage: `url('${event.image}')`}"></div>
            <div class="flexItem__bgOrange"></div>
          </div>
        </div>
      </div>
    </div>
  </default-layout>
</template>

<script>
import Menu from "../components/Menu";
import axios from "axios";
import base__url from "@/config";

export default {
  name: "Events",
  components: {
    Menu: Menu,
  },
  data() {
    return {
      scrolled: false,
      event: {}
    }
  },
  props: {

  },
  mounted() {
    axios.get('/api/events/' + `${this.$route.params.id}`).then(response => (this.event = response.data.event));
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrolled = window.scrollY > 100;
    }
  }
}
</script>

<style scoped lang="scss">
@import "../sass/_variables.scss";

* {
  font-family: $font-primary;
}

.flexItem__limit {
  max-width: 558px;
}

.event-page-component {
  overflow: hidden;
  padding-top: 170px;
}
.flex__iladyParagraphstem, .ladyParagraphs p{
  font-family: $font-secondary;
}
.event_title {
  position: relative;
  font-weight: 250;
  font-size: 30px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #010101;
  margin: 40px 0;
  padding: 40px 0;
}

.event_title:after {
  position: absolute;
  content: '';
  width: 240px;
  height: 1px;
  background: #222222;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and(min-width: 990px){
  .flexable__ladyG_container {
    margin: 80px 0;
    display: grid !important;
    grid-template-columns: 1fr 1fr;
  }
}

.flexable__ladyG_container {
  margin: 80px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.container__flex__item:first-child {
  margin-right: 35px;
}

.container__flex__item:last-child {
  position: relative;
  margin-left: 35px;
  display: flex;
  justify-content: flex-start;
}

.ladyBlock {
  width: 651px;
}

.wine-parent-b {
  margin-top: 60px;
  width: 100%;
  height: 100%;
  position: relative;
}

.ladyBgImg {
  position: relative;
  z-index: 20;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 974px;
  width: 100%;
}

.flexItem__bgOrange {
  background: #CCAD7B;
  position: absolute;
  top: 59px;
  left: 58px;
  width: 100%;
  height: 765px;
}

.lady-bg-rec {
  float: right;
  margin: 25px 0;
  width: 100%;
  height: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lady-bg-rec div {
  background: #CCAD7B;
  height: 100%;
}

.lady-bg-rec div:nth-child(1) {
  width: 65.6%;
}

.lady-bg-rec div:nth-child(2) {
  width: 9.2%;
}

.lady-bg-rec div:nth-child(3) {
  width: 6.6%;
}

.lady-bg-rec div:nth-child(4) {
  width: 5.2%;
}

.lady-bg-rec div:nth-child(5) {
  width: 2.6%;
}

.ladyParagraphs {
  margin: 20px 0;
  max-width: 558px;
  width: 100%;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #010101;
}

.container__flex__item:first-child {
  display: flex;
  position: relative;
  justify-content: flex-end;
}

.container__flex__item:last-child {
  position: relative;
  flex-direction: column;
  width: 100%;
}

.is-color-light {
  margin: 0;
  color: #A1A1A1;
}

.container__flex__item:last-child .lady-bg-rec {
  margin: 32px 0 32px 0;
}

.container__flex__item:last-child .ladyParagraphs {
  margin: 32px 0;
}


.wineBgImg {
  z-index: 20;
  position: relative;
  //background: url('../../public/images/events/flex-item-img2.jpg') no-repeat center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 558px;
  height: 744px;
}

.orangeBg {
  z-index: 2;
  position: absolute;
  background: #CCAD7B;
}

.container__flex__item:first-child .orangeBg {
  height: 646px;
  width: 100%;
  top: 64px;
  left: -50%;
}

.container__flex__item:last-child .orangeBg {
  z-index: 3;
  position: absolute;
  height: 765px;
  width: 580px;
  top: 59px;
  left: 60px;
}

@media screen and (max-width: 1410px){
  .ladyBlock{
    width: 520px;
  }
  .flexItem__limit, .wineBgImg{
    max-width: 446px
  }
}
@media screen and (max-width: 1150px){
  .ladyBlock{
    width: 416px;
  }
  .flexItem__limit, .wineBgImg{
    max-width: 356px
  }
  .orangeBg{
    height: 448px !important;
  }
  .ladyBgImg{
    height: 630px;
  }
  .wineBgImg{
    height: 474px;
  }
  .flexItem__bgOrange{
    height: 480px;
  }
}
@media screen and (max-width: 990px){
  .event-page-component{
    padding-top: 0;
  }
  .flexable__ladyG_container{
    margin-top: 50px;
  }
  .event_title{
    font-size: 23px;
    padding: 25px 0;
  }
  .event-page-component{
    margin-bottom: 64px;
  }
  .flexable__ladyG_container{
    flex-direction: column;
  }
  .container__flex__item{
    justify-content: center !important;
    margin: 0 !important;
  }
  .flexItem__limit{
    margin: 0 auto;
    max-width: 416px;
    width: 100%;
  }
  .wineBgImg{
    max-width: 416px;
    margin: 0 auto;
    height: 556px;
  }
  .flexItem__bgOrange {
    background: #CCAD7B;
    position: absolute;
    top: 63px;
    left: 35%;
    width: 100%;
    height: 559px;
  }
}

@media screen and (max-width: 830px) {
  .flexItem__bgOrange {
    left: 30%;
  }
}
@media screen and (max-width: 580px) {
  .flexItem__bgOrange {
    left: 25%;
  }
}
@media screen and (max-width: 460px) {
  .flexItem__bgOrange {
    left: 15%;
  }
  .event_title{
    font-size: 16px;
    padding: 16px 0;
  }
  .flexable__ladyG_container{
    margin-top: 56px;
  }
  .ladyBgImg{
    height: 440px;
  }
  .ladyBlock, .flexItem__limit, .wineBgImg{
    width: 327px;
  }
  .orangeBg{
    height: 320px !important;
  }
  .flexItem__bgOrange{
    left: 25%;
    top: 30px;
  }

}
@media screen and (max-width: 375px){
  .flexable__ladyG_container{
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
  }
  .flexItem__bgOrange{
    left: 48px;
    top: 30px;
  }
}
</style>