import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Founders from "../views/Founders";
import Contact from "../views/Contact";
import Events from "../views/Events";
import Services from "../views/Services";
import BrandItem from "@/components/BrandItem"
import Brands from "@/views/Brands";

Vue.use(VueRouter)

const routes = [
    {
    path: '/',
    name: 'Home',
    component: Home
    },
    {
        path: '/about',
        name: 'about',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/founders',
        name: 'founders',
        component: Founders
    },
    {
        path: '/contacts',
        name: 'contacts',
        component: Contact
    },
    {
        path: '/events/:id',
        name: 'events',
        component: Events,
        props: true
    },
    {
        path: '/brand/:id',
        name: 'brand',
        component: Brands,
        props: true,
    },
    {
        path: '/partners/:id',
        props: true,
        component: Services,
    },
    {
        path: '/brands/:id',
        name: 'brandItem',
        props: true,
        component: BrandItem,
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

export default router