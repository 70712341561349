<template>
  <footer>
    <div class="footer-container">
      <div class="footer-block">
        <a href="/"><img srcset="/images/KWS.svg,
             /images/KWS-2x.svg 2x"
                         src="/images/KWS.svg" class="menu_logo" alt=""></a>
      </div>
      <div class="footer-block">
        <div class="footer-block-item">
          Все права защищены 2021
        </div>
        <div class="footer-block-item">
          <span class="footer-block-text">{{ footerTitle }}</span>
          <a :href="instagramUrl"><img src="../../public/images/footer/instagram.svg" alt=""></a>
          <a :href="facebookUrl"><img src="../../public/images/footer/facebook.svg" alt=""></a>
        </div>
        <div class="footer-block-item">
          {{ rightFooterText }}
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import axios from "axios";
import base__url from "@/config";

export default {
  name: "TheFooter",
  data() {
    return {
      rightFooterText: {},
      footerTitle: {},
      facebookUrl: {},
      instagramUrl: {},
      footerWatch: {}
    }
  },
  mounted() {
    axios.get('/api/settings/footer.name').then(re => this.rightFooterText = re.data.setting.value)
    axios.get('/api/settings/footer.title').then(re => this.footerTitle = re.data.setting.value)
    axios.get('/api/settings/facebook').then(re => this.facebookUrl = re.data.setting.value)
    axios.get('/api/settings/instagram').then(re => this.instagramUrl = re.data.setting.value)
    axios.get('/api/settings/footer.watch').then(re => this.footerWatch = re.data.setting.value)
  }
};
</script>

<style lang="scss">
@import "src/sass/variables";

* {
  font-family: $font-primary;
}

/*-----------------Footer Style--------------------------------------------*/
.footer-container {
  background: #222222;
  padding: 40px 5.6% 24px 5.6%;
}

.footer-block:first-child {
  margin-bottom: 55px;
  text-align: center;
}

.footer-block:last-child {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
}

.footer-block-item:nth-child(1) {
  flex-grow: 1.2;
}

.footer-block-item:nth-child(3) {
  flex-grow: 1;
}

.footer-block-item:nth-child(3), .footer-block-item:nth-child(1) {
  padding-top: 28px;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.06em;
  color: #585858;
  border-top: 1px solid #585858;
}

.footer-block-item:nth-child(2) {
  margin: -42px 32px 0 32px;
  display: flex;
  height: 30px;
  line-height: 30px;
  align-self: center;
}

.footer-block-item:nth-child(2) img {
  margin: 0 5px;
}

.footer-block-item:nth-child(3) {
  text-align: right;
}

.footer-block-text {
  margin-right: 8px;
}

.footer-block-item {
  svg {
    margin: 0 10px;
  }
}

@media screen and (max-width: 900px) {
  /*--------------------Footer styles---------------------------------*/
  .footer-block {
    margin-bottom: 47px !important;

    img {
      width: 64px;
      height: 20px;
    }
  }
  .footer-block:last-child {
    flex-direction: column;
  }

  .footer-block-item:nth-child(1) {
    order: 2;
    border: none;
  }

  .footer-block-item:nth-child(2) {
    order: 1;
    width: 320px;
    display: flex;
    justify-content: center;
    margin: 0;
    border-bottom: 1px solid #585858;
    padding-bottom: 40px;
  }

  .footer-block-item:nth-child(3) {
    order: 3;
    border: none;
  }
  .footer-block-item {
    img {
      width: 24px;
      height: 24px;
    }
  }
}

@media screen and (max-width: 378px) {
  .footer-block:last-child {
    margin-bottom: 0 !important;
  }
}

@media screen and (max-width: 320px) {
  .footer-block:last-child {
    margin-bottom: 0 !important;
  }
  span.footer-block-text, .footer-block-item {
    font-size: 14px !important;
  }

  .footer-block-item img {
    width: 17px;
  }
  .footer-block-item:nth-child(2) {
    width: 280px;
  }

}
</style>