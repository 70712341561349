import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";
Vue.config.productionTip = false

let lang = localStorage.getItem('lang') || 'ru'

axios.defaults.baseURL = 'https://pma.kws.kz'
axios.defaults.headers['Content-Language'] = lang
//Global components
import DefaultLayout from '@/components/layouts/DefaultLayout'

//Scss global - doesn't actually do anything(?)
import "/src/sass/app.scss"
import "/src/sass/_variables.scss"
import "/src/sass/_fonts.scss"

Vue.component('default-layout', DefaultLayout)


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')