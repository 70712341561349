<template>
  <div class="brand-description">
    <div class="relative__menu"></div>
    <div v-if="Object.keys(descriptionObject).length !== 0">
      <div class="brand-description__title">
        {{brandTitle.value}}
        <div class="brand-dash__small"></div>
      </div>
      <div class="brand-description__text" v-html="descriptionObject.main_text">
      </div>
      <div class="brand-description__features">
        <div class="features__item">
          <div class="features__item__title">{{ descriptionObject.brands_num }}</div>
          <div class="brand-dash__small"></div>
          <div class="features__item__text">
            {{ descriptionObject.first_attribute }}
          </div>
        </div>
        <div class="features__item">
          <div class="features__item__title">{{ descriptionObject.houses_num }}</div>
          <div class="brand-dash__small"></div>
          <div class="features__item__text">{{ descriptionObject.second_attribute }}</div>
        </div>
        <div class="features__item">
          <div class="features__item__title">{{ descriptionObject.third_num }}</div>
          <div class="brand-dash__small"></div>
          <div class="features__item__text">{{ descriptionObject.third_attribute }}</div>
        </div>
      </div>
    </div>
    <div v-else class="loading__div"><img src="../../../public/loading-gif.gif" alt=""></div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: "BrandDescription",
  props: {
    descriptionObject: {
      default() {
        return {
          brandTitle: ''
        }
      }
    }
  },
  mounted() {
    axios.get('api/settings/brands.title').then(response => this.brandTitle = response.data.setting)
  }
};
</script>

<style lang="scss">
@import "src/sass/variables";

.brand-description {
  max-width: 1120px;
  min-height: 500px;
  height: 100%;
  margin: 0 auto;
}

.brand-description__title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 68px;
  /* text settings */
  font-family: $font-primary;
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 30px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  text-align: center;
}

.loading__div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  img {
    width: 150px;
    height: 150px;
  }
}

.brand-dash__small {
  margin: 32px auto;
  background-color: #010101; //rename after
  width: 100px;
  height: 1px;
}

.brand-description__text {
  max-width: 1024px;
  font-family: $font-secondary;
  font-weight: 300;
  font-size: 24px;
  line-height: 34px;
  text-align: center;
  margin: 0 auto;
}

.brand-description__features {
  margin: 80px 0;
  display: flex;
  justify-content: space-evenly;
  font-family: $font-primary;
  font-size: 20px;
  text-align: center;

  .features__item__text {
    max-width: 280px;
    line-height: 32px;
    /* or 160% */

    text-align: center;
    letter-spacing: 0.12em;
    text-transform: uppercase;
  }
}

@media (max-width: 1000px) {
  .brand-description__features {
    margin: 80px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-size: 20px;

    .features__item__text {
      margin: 0 auto;
      margin-bottom: 32px;
      font-family: $font-secondary;
      font-size: 16px;

      text-align: center;
      letter-spacing: 0;
      text-transform: none;
    }
  }

  .brand-description__text {
    margin: 0 20px;
  }

  .brand-dash__small {
    margin: 16px auto;
  }
}
</style>