<template>
  <default-layout style="position: relative">
    <Menu :color="scrolled ? 'scrolledClass' : 'black'"/>
    <div v-if="brandItem.id">
      <div class="relative__menu"></div>
      <div class="wrapper">
        <div class="brand__hero">
          <div class="hero__left">
            <div class="hero__left__title">{{ brandItem.title }}</div>
            <div class="hero__left__text" v-html="brandItem.description"></div>
          </div>
          <div class="hero__right">
            <img :src="brandItem.logo" alt=""/>
          </div>
        </div>
        <div class="hero__image">
          <div class="hero__image__background"></div>
          <img :src="brandItem.first_img" alt=""/>
        </div>
      </div>
      <div class="hero__notes">
        <p v-html="brandItem.brand_info"></p>
      </div>

      <div class="brand__showcase">
        <div class="brand__showcase__upper__block">
          <div class="brand__showcase__left">
            <div class="brand__showcase__background"></div>
            <img :src="brandItem.sec_img" alt=""/>
          </div>
          <div class="brand__showcase__right">
            <div class="brand__showcase__text">
              <h1>{{ brandItem.secondary_title }}</h1>
              <p>{{ brandItem.main_text }}</p>
            </div>
            <div class="brand__showcase__decoration">
              <div class="decoration__bar first"></div>
              <div class="decoration__bar second"></div>
              <div class="decoration__bar third"></div>
              <div class="decoration__bar fourth"></div>
            </div>
            <img :src="brandItem.third_img" alt=""/>
          </div>
        </div>
        <div class="brand__showcase__bottom__block">
          <div class="brand__showcase__decoration">
            <div class="decoration__bar first"></div>
            <div class="decoration__bar second"></div>
            <div class="decoration__bar third"></div>
          </div>
          <img :src="brandShowcase.wideImage" alt=""/>
          <img :src="brandItem.main_img" alt=""/>
        </div>
      </div>

      <div v-if="brandItem.products.length" class="brand__products">
        <div class="brand__products__title">
          <p>товары</p>
          <div class="brand__products__decoration">
            <div class="decoration__bar first"></div>
            <div class="decoration__bar second"></div>
            <div class="decoration__bar third"></div>
            <div class="decoration__bar fourth"></div>
          </div>
        </div>
        <div class="brand__products__carousel">
          <button
              @click.prevent="slidePrev"
              class="brand__products__carousel__btn btn__left"
          >
            <svg
                width="12"
                height="18"
                viewBox="0 0 12 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  d="M1 9.00024L11 17.3336"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
              />
              <path
                  d="M0.999999 9.00007L11 0.666749"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
              />
            </svg>
          </button>
          <hooper :settings="hooperSettings" ref="carousel">
            <slide v-for="(product, index) in brandItem.products" :key="index">
              <div class="carousel__item">
                <div class="carousel__item__image">
                  <!--                <img :src="'https://brut.kz' + product.image" alt=""/>-->
                  <a :href="'https://brut.kz/product/' + product.slug">
                    <img v-if="product.image.includes('kws.kz')" src="https://brut.kz/img/plug.png" alt="">
                    <img v-else :src="'https://brut.kz' + product.image" alt=""/>
                  </a>
                </div>
                <a :href="'https://brut.kz/product/' + product.slug">
                  <div class="carousel__item__title">{{ exactData(product.attribute_values).title }}</div>
                  <div class="carousel__item__description">
                    {{ exactData(product.attribute_values).description }}
                  </div>
                </a>
              </div>
            </slide>
          </hooper>
          <button
              @click.prevent="slideNext"
              class="brand__products__carousel__btn btn__right"
          >
            <svg
                width="12"
                height="18"
                viewBox="0 0 12 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  d="M11 8.99976L1 0.666436"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
              />
              <path
                  d="M11 8.99993L1 17.3333"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>

      </div>
    </div>
    <div v-else class="else__loading">
      <img src="../../public/loading-gif.gif" alt="">
    </div>
  </default-layout>
</template>

<script>
import {Hooper, Slide} from "hooper";
import "hooper/dist/hooper.css";
import Menu from "../components/Menu";
import axios from "axios";
import base__url from "@/config";

export default {
  name: "brandItem",
  props: ['id'],
  data() {
    return {
      brandItem: {},
      scrolled: false,
      windowWidth: window.innerWidth,
      btnColorLeft: "#C49955",
      btnColorRight: "#C49955",
      brandShowcase: {},
      brandProducts: [
      ],
      hooperSettings: {
        itemsToShow: 1,
        // infiniteScroll: true,
        centerMode: true,
        breakpoints: {
          1000: {
            centerMode: false,
            itemsToShow: 4,
          },
          800: {
            centerMode: false,
            itemsToShow: 2,
          },
        },
        wheelControl: false
      },
    };
  },
  components: {
    Menu,
    Hooper,
    Slide,
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    axios.get('/api/brands/' + this.id).then(
        re => (this.brandItem = re.data)
    )
  },
  computed: {
    wideImage() {
      if (this.windowWidth <= 571) {
        return this.brandShowcase.wideImageMobile;
      } else {
        return this.brandShowcase.wideImageDesktop;
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    exactData(attribute_values){
      let dataList = {
        title: attribute_values.filter(item => item.attribute_id == 17)[0] ? attribute_values.filter(item => item.attribute_id == 17)[0].name : ' ',
        description: attribute_values.filter(item => item.attribute_id == 18)[0] ? attribute_values.filter(item => item.attribute_id == 18)[0].name : ' '
      }
      return dataList
    },
    handleScroll() {
      this.scrolled = window.scrollY > 100;
    },
    slidePrev() {
      this.btnColorLeft = "white";
      this.btnColorRight = "#C49955";
      this.$refs.carousel.slidePrev();
    },
    slideNext() {
      this.btnColorLeft = "#C49955";
      this.btnColorRight = "white";
      this.$refs.carousel.slideNext();
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>

<style lang="scss">
@import "src/sass/variables";

.brand__hero {
  display: flex;
  justify-content: space-between;
  margin: 80px 0 113px 0;
}
.else__loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  img {
    width: 200px !important;
    height: 150px !important;
  }
}
.hero__left {
  color: $color-black;

  .hero__left__title {
    margin: 32px auto;
    font-family: $font-primary;
    font-size: 30px;
    line-height: 30px;
    letter-spacing: 0.12em;
    font-weight: 300;
    text-transform: uppercase;
  }

  .hero__left__text {
    max-width: 660px;
    width: 100%;
    font-family: $font-secondary;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
  }
}

.hero__right {
  margin: auto 40px;
  max-width: 498px;
  max-height: 280px;

  img {
    max-width: 498px;
    max-height: 280px;
  }
}

.brand__products {
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -o-user-select: none !important;
  user-select: none !important;
}

@media screen and (max-width: 768px) {
  .hero__right {
    margin: 0 auto;
  }
  .brand__products {
    padding: 0 16px !important;
  }
}

.hero__image {
  position: relative;
  margin: 0 auto;
  width: 100%;

  .hero__image__background {
    position: absolute;
    z-index: 1;
    background-color: $color-brown-easy;
    height: 105%;
    width: 60%;
    bottom: 5px;
    right: 5%;
  }

  img {
    position: relative;
    z-index: 2;
    object-fit: cover;
    height: 480px;
    width: 100%;
  }
}

.hero__notes {
  margin: 40px 0;
  padding: 16px 0;
  background-color: $color-brown;
  width: 100%;

  p {
    color: white;
    margin: 10px auto;
    text-align: center;
  }
}

// Showcase desktop
.brand__showcase {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 40px;
  position: relative;

  .brand__showcase__upper__block {
    display: flex;
    justify-content: space-between;
  }

  .brand__showcase__left {
    height: 60%;
    position: relative;
    z-index: 2;
    width: 100%;
    margin-bottom: 40px;

    img {
      position: relative;
      z-index: 2;
      width: 90%;
      margin-bottom: 32px;
    }
  }

  .brand__showcase__right {
    max-width: 580px;
    width: 100%;

    .brand__showcase__text {
      position: relative;
      z-index: 2;
      padding: 0 16px;
      margin-bottom: 80px;

      h1 {
        font-family: $font-primary;
        font-weight: 300;
        font-size: 30px;
        line-height: 30px;
        letter-spacing: 0.12em;
        text-transform: uppercase;
        margin-bottom: 32px;
      }

      p {
        font-family: $font-secondary;
      }
    }

    img {
      position: relative;
      z-index: 2;
      object-fit: cover;
      width: 100%;
    }

    .brand__showcase__decoration {
      margin: 24px auto;
      display: flex;
      flex-direction: row-reverse;
      height: 64px;
      width: 100%;
      background-color: $color-brown-easy;

      .decoration__bar {
        height: 100%;
        width: 16px;
        background-color: white;
      }

      .first {
        margin-right: 16px;
      }

      .second {
        margin-right: 30px;
      }

      .third {
        margin-right: 40px;
      }

      .fourth {
        margin-right: 54px;
      }
    }
  }

  .brand__showcase__bottom__block {
    position: relative;
    margin: 80px auto;

    .brand__showcase__decoration {
      position: absolute;
      top: -100px;
      display: flex;
      height: 64px;
      width: 560px;
      background-color: $color-brown-easy;

      .decoration__bar {
        height: 100%;
        width: 32px;
        background-color: white;
      }

      .first {
        margin-left: 32px;
      }

      .second {
        margin-left: 64px;
      }

      .third {
        margin-left: 80px;
      }
    }

    img {
      width: 100%;
      position: relative;
      z-index: 2;
    }
  }

  .brand__showcase__background {
    position: absolute;
    z-index: 1;
    background-color: $color-brown-easy;

    width: 100%;
    height: 330px;
    right: 0;
    bottom: 0;
  }
}

// Brand products desktop

.brand__products {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 40px;

  .brand__products__title {
    display: flex;
    justify-content: space-between;

    p {
      margin: auto 0;
      margin-right: 25px;
      // font settings
      font-family: $font-primary;
      font-weight: 300;
      font-size: 30px;
      line-height: 30px;
      letter-spacing: 0.12em;
      text-transform: uppercase;
    }

    .brand__products__decoration {
      display: flex;
      height: 64px;
      max-width: 1024px;
      width: 100%;
      background-color: $color-brown-easy;

      .decoration__bar {
        height: 100%;
        width: 26px;
        background-color: white;
      }

      .first {
        margin-left: 30px;
      }

      .second {
        margin-left: 56px;
      }

      .third {
        margin-left: 70px;
      }

      .fourth {
        margin-left: 96px;
      }
    }
  }

  .brand__products__carousel {
    position: relative;
    margin: 50px auto;
    padding: 0 45px;

    .hooper {
      height: 560px;
    }

    .brand__products__carousel__btn {
      outline: none;
      position: absolute;
      z-index: 2;
      width: 40px;
      height: 56px;
      background-color: white;
      border: 1px solid $color-brown;
      border-radius: 2px;
      top: 50%;
      transform: translate(0, -50%);
      color: $color-brown;
    }

    .brand__products__carousel__btn:focus {
      background-color: $color-brown;
      color: white;
    }

    .btn__left {
      left: 0;
    }

    .btn__right {
      right: 0;
    }

    a {
      text-decoration: none;
      color: inherit;
    }

    .carousel__item {
      //margin: 0 auto;
      margin-top: 20px;
      padding: 20px 0;
      height: max-content;
      // max-width: 280px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .carousel__item__image {
        img {
          margin: 0 auto;
          display: block;
          min-height: 310px;
          max-height: 311px;
        }
      }
    }

    .carousel__item:hover {
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
    }

    .carousel__item__title {
      margin-top: 24px;
      //font settings
	min-height: 74px;
      text-align: center;
      font-family: $font-primary;
      font-weight: 300;
      font-size: 24px;
      line-height: 34px;
      letter-spacing: 0.12em;
      text-transform: uppercase;
    }

    .carousel__item__description {
      text-align: center;
      font-family: $font-secondary;
    }
  }
}

@media (max-width: 1000px) {
  .brand__hero {
    flex-direction: column-reverse;
    text-align: center;

    img {
      max-width: 311px;
      max-height: 192px;
    }
  }
  .hero__image {
    .hero__image__background {
      bottom: 10px;
    }

    img {
      height: 285px;
    }
  }
  .hero__notes {
    p {
      padding: 0 16px;
    }
  }

  .brand__showcase {
    padding: 0;

    .brand__showcase__left {
      margin-bottom: 60px;
      padding: 0 40px;

      .brand__showcase__background {
        right: 4%;
        width: 90%;
        bottom: 15px;
      }

      img {
        position: relative;
        z-index: 2;
        width: 98%;
        margin-bottom: 32px;
      }
    }

    .brand__showcase__right {
      max-width: 1000px;

      img {
        padding: 0 10%;
      }

      .brand__showcase__decoration {
        height: 40px;

        .decoration__bar {
          width: 20px;
        }
      }
    }

    .brand__showcase__upper__block {
      flex-direction: column;
    }

    .brand__showcase__bottom__block {
      .brand__showcase__decoration {
        height: 40px;
        position: relative;
        top: -40px;
        width: 100%;

        .decoration__bar {
          width: 20px;
        }
      }

      img {
        padding: 0 16px;
      }
    }
  }

  .brand__products {
    padding: 0;

    .brand__products__title {
      display: flex;
      justify-content: space-between;

      p {
        margin-left: 40px;
      }

      .brand__products__decoration {
        height: 40px;

        .decoration__bar {
          width: 16px;
        }

        .first {
          margin-left: 4%;
        }

        .second {
          margin-left: 8%;
        }

        .third {
          margin-left: 12%;
        }

        .fourth {
          margin-left: 14%;
        }

        .brand__products__carousel {
          padding: 0 85px;

          .btn__left {
            left: 25px;
          }

          .brand__products__carousel {
            padding: 0 85px;

            .btn__left {
              left: 25px;
            }

            .btn__right {
              right: 25px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .brand__showcase {
    .brand__showcase__right {
      img {
        padding: 0 4%;
      }

      .brand__showcase__decoration {
        .decoration__bar {
          width: 10px;
        }

        .first {
          margin-right: 8px;
        }

        .second {
          margin-right: 16px;
        }

        .third {
          margin-right: 20px;
        }

        .fourth {
          margin-right: 40px;
        }
      }
    }

    .brand__showcase__bottom__block {
      .brand__showcase__decoration {
        .decoration__bar {
          width: 10px;
        }

        .first {
          margin-left: 16px;
        }

        .second {
          margin-left: 32px;
        }

        .third {
          margin-left: 40px;
        }
      }
    }
  }
  .brand__products {
    .brand__products__carousel {
      padding: 0;
    }

    .brand__products__title {
      .brand__products__decoration {
        .decoration__bar {
          width: 10px;
        }
      }
    }
  }
}

</style>
