<template>
  <div class="services__hero">
    <div class="relative__menu"></div>
    <div class="services__hero__banner">
      <img :src="heroImage" alt=""/>
      <h1>{{ heroText }}</h1>
    </div>
    <div class="services__hero__tab">
      <div class="wrapper">
        <div class="services__hero__tab__navigation">
          <div class="navigation__item" v-for="partner in partners" :key="partner.id">
            <router-link v-if="!partner.parent_id" :to="{path: `${partner.id}`, params: {id: partner.id} }">{{ partner.title }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import base__url from "@/config";

export default {
  name: "ServicesHero",
  data() {
    return {
      partners: [],
      routerId: this.$route.params.id
    }
  },
  props: ["heroImage", "heroText"],
  mounted() {
    axios.get('/api/partners').then(response => (this.partners = response.data.partner))
  }
};
</script>

<style lang="scss">
@import "src/sass/variables";


.services__hero__banner {
  background-color: $color-dark;
  position: relative;
  margin: 0 auto;

  img {
    filter: brightness(50%);
    object-fit: cover;
    height: 400px;
    width: 100%;
  }

  h1 {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    margin: 0;
    width: 80%;
    // font stuff
    font-family: $font-primary;
    font-style: normal;
    font-weight: 200;
    font-size: 56px;
    line-height: 56px;
    letter-spacing: 0.12em;
    text-align: center;
    text-transform: uppercase;
    color: white;
  }
}

.services__hero__tab {
  background-color: $color-dark;
}

.services__hero__tab__navigation {
  padding: 24px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;

  .navigation__item {
    margin-left: 72px;
    font-family: $font-primary;
    font-style: normal;
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    white-space: nowrap;

    a {
      text-decoration: none;
      color: white;
    }

    .router-link-exact-active {
      color: #CCAD7B;
    }
  }

  .navigation__item:last-child {
    margin-right: 72px;
  }

  .navigation__item:first-child {
    margin-left: 0;
  }
}
.services__hero__tab__navigation::-webkit-scrollbar {
  display: none;
}
@media (max-width: 768px) {
  .services__hero__banner {
    img {
      height: 300px;
    }

    h1 {
      font-size: 25px;
      line-height: 34px;
    }
  }
  .services__hero__tab {
    .navigation__item {
      margin-left: 32px;
    }
  }
}
</style>