<template>
  <div v-if="heroImageDesktop">
    <div class="brands__hero">
      <img :src="heroImageDesktop.img" alt=""/>
      <h1>{{ heroImageDesktop.name }}</h1>
    </div>
    <div class="brands__tab">
      <div class="wrapper">
        <div class="brands__tab__navigation">
          <div class="navigation__item" v-for="(link, index) in routerLinks" :key="index">
            <!--            <router-link :to="{path: `/brand/${link.id}`, params: {id: link.id}}">{{ link.name }}</router-link>-->
            <a :href="'/brand/' + link.id" :class="(routerId == link.id)?'active':'not_active'">{{ link.name }}</a>
          </div>
        </div>
      </div>
    </div>

    <div class="brands__content">
      <div class="brands__filter">
        <!--
          MOBILE FILTER HTML
          -->
        <div class="filter__mobile">
          <div class="filter__mobile__btn" @click="openFilterModal">
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  d="M21.375 6.97067H20.0365C19.7569 5.84065 18.7833 5 17.6177 5C16.4521 5 15.4784 5.84065 15.1988 6.97067H2.625C2.27995 6.97067 2 7.26375 2 7.625C2 7.98625 2.27995 8.27933 2.625 8.27933H15.1988C15.4784 9.40935 16.4521 10.25 17.6177 10.25C18.7833 10.25 19.7569 9.40935 20.0365 8.27933H21.375C21.7201 8.27933 22 7.98625 22 7.625C22 7.26375 21.7201 6.97067 21.375 6.97067ZM17.6177 8.94133C16.9243 8.94133 16.3604 8.3509 16.3604 7.625C16.3604 6.8991 16.9243 6.30867 17.6177 6.30867C18.311 6.30867 18.875 6.8991 18.875 7.625C18.875 8.3509 18.311 8.94133 17.6177 8.94133Z"
                  fill="#010101"
                  fill-opacity="0.5"
              />
              <path
                  d="M21.375 15.7207H9.26512C8.98552 14.5907 8.01196 13.75 6.847 13.75C5.68139 13.75 4.70777 14.5907 4.42813 15.7207H2.625C2.27995 15.7207 2 16.0138 2 16.375C2 16.7362 2.27995 17.0293 2.625 17.0293H4.42813C4.70777 18.1593 5.68139 19 6.847 19C8.01196 19 8.98552 18.1593 9.26512 17.0293H21.375C21.7201 17.0293 22 16.7362 22 16.375C22 16.0138 21.7201 15.7207 21.375 15.7207ZM6.847 17.6913C6.15365 17.6913 5.58968 17.1009 5.58968 16.375C5.58968 15.6491 6.15365 15.0587 6.847 15.0587C7.53955 15.0587 8.10352 15.6491 8.10352 16.375C8.10352 17.1009 7.53955 17.6913 6.847 17.6913Z"
                  fill="#010101"
                  fill-opacity="0.5"
              />
            </svg>
            <span>Фильтр</span>
          </div>
          <div class="filter__mobile__modal" :class="{ show: filterOpened }">
            <div class="filter__modal__close__btn">
              <span>Фильтр</span>
              <svg
                  @click="closeFilterModal"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M13 0.999958L1 13"
                    stroke="#222222"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M1 0.999958L13 13"
                    stroke="#222222"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
              </svg>
            </div>
            <div class="filter__modal__category" v-for="(category, index) in categories" :key="index">
              <div class="category__label collapsed" data-toggle="collapse" :data-target="'#collapsingNavbar' + index">
                <span>{{ category.name }}</span>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.5825 12L15 10.486L8 3.00002L1 10.486L2.4175 12L8 6.03284L13.5825 12Z" fill="#222222"/>
                </svg>
              </div>
              <ul class="category__list collapse" :id="['collapsingNavbar' + index]">
                <li class="category__list__item">
                  <input type="radio" name="selectionCountry" :id="'inMobileShowAllCountry' + index">
                  <label
                      :for="'inMobileShowAllCountry' + index"
                      @click="sortCountryList('all')"
                      :class="filteredByCountries.length == iterateBrandsList.length ? 'active' : ''">Все</label>
                </li>
                <li class="category__list__item" v-for="(item, index) in category.list" :key="index">
                  <input type="radio" name="selectionCountry" v-if="item.name" :id="'MobileFilter1' + item.id">
                  <input type="radio" name="selectionCountry" v-if="item.title" :id="'MobileFilter2' + item.id">
                  <label :for="'MobileFilter1' + item.id" v-if="item.name"
                         @click="sortCountryList(item.id)">{{ item.name }}</label>
                  <label :for="'MobileFilter2' + item.id" v-if="item.title"
                         @click="filterByAlcoholType(item.id)">{{ item.title }}</label>
                </li>
              </ul>
            </div>
            <div class="filter__modal__category">
              <div class="category__list__item">
                <input type="radio" name="selectionCountry" id="showAllCountryBrands5">
                <label
                    @click="sortCountryList('all')"
                    :class="filteredByCountries.length == iterateBrandsList.length ? 'active' : ''"
                    for="showAllCountryBrands5">Все</label>
              </div>
              <div class="filter__letters__list">
                <div class="filter__letters__list__item" v-for="(item, index) in filterLetters" :key="index"
                     @click="filterByLetter(item)">
                  <input type="radio" name="selectionCountry" :id="'elementMobile' + index">
                  <label :for="'elementMobile' + index"
                         :class="(activeLetter) ? 'activeLetter' : 'inActiveLetter'">{{ item }}</label>
                </div>
              </div>
            </div>
            <div class="filter__mobile__modal__bottom">
              <div class="filter__confirm__btn" @click="closeFilterModal">Применить</div>
            </div>
          </div>
        </div>
        <!--
          DESKTOP FILTER HTML
         -->
        <div class="filter__desktop">
          <div class="filter__list">
            <div class="filter__all">
              <input type="radio" name="selectionCountry" id="showAllCountryBrands">
              <label id="DesktopShowAllLabel"
                     @click="sortCountryList('all')"
                     :class="filteredByCountries.length == iterateBrandsList.length ? 'active' : ''"
                     for="showAllCountryBrands">Все</label>
            </div>
            <ul class="filter__countries">
              <li v-for="(item, index) in filterCountries" :key="index">
                <input type="radio" :id="'desktopCountry'+item.id" name="selectionCountry" :value="item"
                       v-model="checkedList">
                <label
                    @click="sortCountryList(item.id)"
                    :for="'desktopCountry'+item.id">{{ item.name }}
                </label>
              </li>
            </ul>
          </div>
          <div class="filter__list">
            <div class="filter__all">
              <input type="radio" name="selectionCountry" id="showAllCountryBrands3">
              <label
                  @click="sortCountryList('all')"
                  :class="filteredByCountries.length == iterateBrandsList.length ? 'active' : ''"
                  for="showAllCountryBrands3">Все</label>
            </div>
            <ul class="filter__letters">
              <li v-for="(item, index) in filterLetters" :key="index" @click="filterByLetter(item)">
                <input type="radio" name="selectionCountry" :id="'element' + index">
                <label :for="'element' + index" :class="(activeLetter) ? 'activeLetter' : 'inActiveLetter'">{{
                    item
                  }}</label>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="brands__grid">
        <div class="grid__header">
          <div class="grid__header__text">
            {{ heroImageDesktop.subtitle }}
          </div>
          <div class="grid__header__decoration">
            <div class="grid__header__decoration__bar first"></div>
            <div class="grid__header__decoration__bar second"></div>
            <div class="grid__header__decoration__bar third"></div>
            <div class="grid__header__decoration__bar fourth"></div>
          </div>
        </div>
        <div class="grid__list">
          <a :href="'/brands/' + product.id" class="grid__list__item"
             v-for="(product, index) in filteredByCountries"
             v-if="index < toShow"
             :key="index">
            <div class="grid__list__item__image" :style="{backgroundImage: `url(${product.logo})`}">
            </div>
            <div class="grid__list__item__title" v-if="product.country_id">
              {{ filterCountries.filter(item => (item.id === product.country_id))[0].name }}
            </div>
          </a>
        </div>
        <div class="grid__btn" v-if="filteredByCountries.length > toShow"><p @click="toShow += 6">Показать ещё</p></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "BrandsContent",

  props: ["heroImageDesktop"],

  data() {
    return {
      currentPageId: this.$route.params.id,
      activeLetter: false,
      windowWidth: window.innerWidth,
      activeCategory: undefined,
      activeIndex: undefined,
      filterOpened: false,
      checked: true,
      routerLinks: [],
      checkedList: [],
      filteredBrandsIdList: [],
      categories: [
        {
          name: "Страны",
          list: [],
        },
        {
          name: "Коллекции",
          list: [],
        },
      ],
      filterCountries: [],
      filterLetters: [
        "A", "B", "C", "D", "E", "F", "G",
        "H", "I", "J", "K", "L", "M", "N",
        "O", "P", "R", "S", "T", "U", "V",
        "W", "Y", "Z",
      ],
      iterateBrandsList: [],
      testList: [],
      showAllCountry: [],
      countrySubtitle: '',
      toShow: 9,
      routerId: this.$route.params.id,
      filteredByCountries: []
    };
  },
  async mounted() {
    try {
      this.$nextTick(() => {
        window.addEventListener("resize", this.onResize);
      });
      // get filter country list
      // axios.get('/api/brands/main/filters').then(response => (this.filterCountries = response.data.countries))

      // get filter country list
      // axios.get('/api/brands/main/filters').then(response => (this.categories[0].list = response.data.countries))
      axios.get(`/api/brands/main/filters?alcohol_type_id=${this.$route.params.id}`).then(response => (this.categories[0].list = response.data.countries))
      axios.get(`/api/brands/main/filters?alcohol_type_id=${this.$route.params.id}`).then(response => (this.filterCountries = response.data.countries))

      // get filter country list
      axios.get('/api/brands/main/filters').then(response => (this.categories[1].list = response.data.alcohols))

      //  get all alcohol types and their link

      axios.get('/api/alcohol-types/').then(re => (this.routerLinks = re.data.types))

      //  send request to get all brand by country id
      axios.get('/api/alcohol-types/' + this.$route.params.id).then(response => {
        this.iterateBrandsList = response.data.type.brands
        this.filteredByCountries = response.data.type.brands
        // this.filterByAlcoholType(this.$route.params.id)
      })

      //  get country subtitle
      axios.get('/api/settings/brands.brands_history').then(r => (this.countrySubtitle = r.data.setting))
    } catch
        (err) {
      console.log(err)
    }
    this.showAllCountryBrands()
  },

  watch: {
    checkedList: function () {
      let testListing = []
      for (let x = 0; x < this.checkedList.length; x++) {
        for (let y = 0; y < this.iterateBrandsList.length; y++) {
          if (this.checkedList[x] === this.iterateBrandsList[y].country_id) {
            testListing.push(this.iterateBrandsList[y])
          }
        }
      }
      this.testList = testListing
    },
    showAllCountry: function () {
      this.testList = document.getElementById('MobileShowAllLabel').value
    },
  },
  computed: {
    heroImage() {
      if (this.windowWidth <= 571) {
        return this.heroImageMobile;
      } else {
        return this.heroImageDesktop;
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  beforeMount() {
    this.sortCountryList('all');
  },
  methods: {
    showAllCountryBrands() {
      // let desktop = document.getElementById('DesktopShowAllLabel')
      // let mobile = document.getElementById('MobileShowAllLabel')
      // desktop.classList.add('active')
      desktop.value = this.heroImageDesktop.brands
      // mobile.classList.add('active')
      mobile.value = this.heroImageDesktop.brands
      for (let x = 0; x < this.filterCountries.length; x++) {
        document.getElementById('labelId' + this.filterCountries[x].id).classList.remove('active')
        document.getElementById('desktopLabelId' + this.filterCountries[x].id).classList.remove('active')
      }
    },
    sortCountryList(country_id) {
      let elements = []
      let brandItemList = this.heroImageDesktop.brands.filter(countryId => countryId.country_id == country_id)
      if (country_id != 'all') {
        for (let x = 0; x < brandItemList.length; x++) {
          elements.push(brandItemList[x])
        }
        this.filteredByCountries = elements
      } else {
        this.filteredByCountries = this.iterateBrandsList
      }
    },
    openFilterModal() {
      this.filterOpened = true;
      document.body.classList.add("scroll__lock");
    },
    closeFilterModal() {
      this.filterOpened = false;
      document.body.classList.remove("scroll__lock");
    },
    setActive(categoryName, index) {
      this.activeIndex = index;
      this.activeCategory = categoryName;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    filterByLetter(letter) {
      let checkList = []
      for (let x = 0; x < this.iterateBrandsList.length; x++) {
        // console.log(this.iterateBrandsList[x].title)
        if (this.iterateBrandsList[x].title.startsWith(letter, 0)) {
          checkList.push(this.iterateBrandsList[x])
        }
      }
      if (checkList.length) {
        this.filteredByCountries = checkList
      } else {
        this.filteredByCountries = [{'country_title': 'No'}]
      }
    },
    filterByAlcoholType(id) {
      let filteredByAlcoholTypeList = []
      for (let x = 0; x < this.iterateBrandsList.length; x++) {
        if (this.iterateBrandsList[x].alcohol_id == id) {
          filteredByAlcoholTypeList.push(this.iterateBrandsList[x])
        }
      }
      this.filteredByCountries = filteredByAlcoholTypeList
    }
  },
};
</script>

<style lang="scss">
@import "src/sass/variables";

.brands__tab__navigation {
  .router-link-active {
    color: #CCAD7B !important;
  }
}

#showAll3 {
  width: 0;
  height: 0;
}
.category__list__item {
  input:checked ~ label {
    color: #CCAD7B !important
  }
}

.country__subtitle {
  font-weight: bold;
  margin: 16px 0;
}

#showAllCountryBrands:checked ~ label, #showAll2:checked ~ label {
  color: #CCAD7B !important
}

.brands__hero {
  background-color: $color-dark;
  position: relative;

  margin: 0 auto;

  img {
    object-fit: cover;
    height: 400px;
    width: 100%;
  }

  h1 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15%;
    margin: 0;
    // font stuff
    font-family: $font-primary;
    font-style: normal;
    font-weight: 250;
    font-size: 56px;
    line-height: 56px;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    color: white;
  }
}

.brands__tab {
  background-color: $color-dark;
}

.brands__tab__navigation {
  padding: 32px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: none;
  }


  .navigation__item {
    margin-left: 72px;
    color: white;
    font-family: $font-primary;
    font-style: normal;
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    white-space: nowrap;

    a {
      color: unset;
      text-decoration: unset;
    }

    .active {
      color: #CCAD7B;
    }
  }

  .navigation__item:last-child {
    margin-right: 72px;
  }

  .navigation__item:first-child {
    margin-left: 0;
  }
}

// Brands content desktop

.brands__content {
  display: flex;
  justify-content: space-around;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 20px;
}

//filter
.brands__filter {
  position: relative;
  font-family: $font-primary;
  letter-spacing: 0.12em;
  text-transform: capitalize;

  .filter__all {
    margin-bottom: 24px;

    .active {
      color: #CCAD7B !important;
    }

    input {
      width: 0;
      height: 0;
    }

    label {
      margin: 0;
      color: #010101;
      cursor: pointer;
    }
  }

  .category__list__item {
    position: relative;
    list-style-type: none;
    margin: 16px 0;

    input {
      width: 0;
      height: 0;

      &:checked ~ label {
        color: #C49955;
      }
    }

    label {
      margin-bottom: 0;
    }

  }

  .filter__mobile {
    display: none;
    margin: 32px 0;

    .filter__mobile__btn {
      display: flex;
      align-items: center;
      color: #808080;
      margin-left: 40px;

      span {
        margin-left: 10px;
      }
    }

    .filter__mobile__modal {
      top: 0;
      left: 0;
      display: none;
      position: fixed;
      background-color: white;
      z-index: 3;
      height: 100vh;
      width: 100%;
      padding: 16px;
      overflow: auto;

      span {
        font-weight: 700;
        letter-spacing: 0;
      }

      .filter__modal__close__btn {
        display: flex;
        justify-content: space-between;
        padding: 16px 0;
        border-bottom: 1px solid #d6d6d6;

        svg {
          margin-right: 10px;
          margin-top: 5px;
        }
      }

      .filter__modal__category {
        // padding: 0 16px;
        display: flex;
        flex-direction: column;
      }

      .filter__item {
        margin: 16px 0;
      }

      .big-margin {
        margin-bottom: 98px;
      }

      .category__label {
        width: 100%;
        padding: 16px 0;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #d6d6d6;

        svg {
          margin-right: 10px;
          margin-top: 5px;
          transition: ease 0.2s;
        }
      }

      .collapsed {
        svg {
          transform: translateY(-2px) rotate(180deg);
        }
      }

      .category__list {
        padding: 0;
        margin: 0;
      }

      .category__list__item {
        position: relative;
        list-style-type: none;
        margin: 16px 0;
        #test{
          &:checked ~ label {
            color: #C49955;
          }
        }
        input {
          width: 0;
          height: 0;

          &:checked ~ label {
            color: #C49955;
          }
        }

        label {
          margin-bottom: 0;
        }

      }

      .active__item {
        font-weight: 700;

        &::after {
          background-color: $color-brown;
          border-color: $color-brown;
        }
      }

      .filter__letters__list {
        display: grid;
        grid-template-columns: repeat(18, 1fr);

        .filter__letters__list__item {
          margin-right: 5px;
          margin-bottom: 5px;

          input[type='radio'] {
            width: 0;
            height: 0;

            &:checked ~ label {
              color: #C49955;
            }
          }
        }
      }
    }

    .show {
      display: block;
      padding-top: 64px;
    }

    .filter__mobile__modal__bottom {
      position: fixed;
      background-color: white;
      left: 0;
      bottom: 0;
      width: 100%;
      padding: 16px;

      .filter__confirm__btn {
        margin: 0 auto;
        text-align: center;
        letter-spacing: 0;
        width: 80%;
        padding: 20px 0;
        border: 1px solid #c1c1c1;
        border-radius: 2px;
        transition: ease 0.2s;
      }

      .filter__confirm__btn:hover {
        background-color: $color-dark;
        color: #c1c1c1;
      }
    }
  }

  .filter__desktop {
    display: block;

    .filter__all {
      -moz-user-select: none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .filter__list {
      margin: 80px 0;
    }

    .filter__countries {
      padding: 0;
      margin: 0;

      li {
        margin: 16px 0;
        list-style-type: none;

        label {
          font-weight: 400;
          cursor: pointer !important;
          color: #010101 !important;
          -moz-user-select: none;
          -khtml-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none;
          transition: 0.1s;

          &:hover {
            color: #CCAD7B !important;
          }
        }

        input[type='radio']:checked ~ label {
          color: #CCAD7B !important;
        }

        input {
          display: none;
        }
      }
    }

    .filter__letters {
      padding: 0;
      margin: 0;
      text-align: center;

      display: grid;
      grid-template-columns: repeat(10, 1fr);

      li {
        list-style-type: none;
        margin: 8px 0;

        input[type='radio'] {
          width: 0;
          height: 0;
        }

        input[type='radio']:checked ~ label {
          color: #CCAD7B !important;
        }

        label {
          cursor: pointer;
        }
      }
    }

    .filter__alphabetical {
      margin-top: 10px;
    }
  }
}

.activeLetter {
  color: orange;
}

// Brands grid desktop
.brands__grid {
  margin: 0 0 40px;
  //grid header desktop
  .grid__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 946px;
    width: 100%;
    justify-content: space-between;
    margin: 32px auto;

    .grid__header__text {
      font-family: $font-secondary;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      max-width: 660px;
    }

    .grid__header__decoration {
      background-color: $color-brown-easy;
      width: 286px;
      display: flex;
      height: 64px;
      margin: 16px 0;

      .grid__header__decoration__bar {
        background-color: white;
        width: 16px;
      }

      .first {
        display: none;
        margin-left: 16px;
      }

      .second {
        margin-left: 16px;
      }

      .third {
        margin-left: 32px;
      }

      .fourth {
        margin-left: 40px;
      }
    }
  }

  // grid list desktop
  .grid__list {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(3, 1fr);

    .grid__list__item {
      position: relative;
      height: 300px;
      width: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 24px 24px 24px;
      box-sizing: border-box;
      background-color: $color-grey;

      &:hover {
        text-decoration: none;
      }

      .grid__list__item__image {
        display: flex;
        align-items: center;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        justify-content: center;
        flex: 1 1 auto;
        margin: 24px 24px 0 24px;

      }

      .grid__list__item__title {
        display: block;
        font-family: $font-primary;
        color: $color-black;
        letter-spacing: 0.12em;
        text-transform: capitalize;
        text-align: center;

        span {
          display: block;
          margin-bottom: 2px;
        }
      }

      .grid__list__item__dash {
        display: none;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 80%;
        height: 1px;
        background-color: #d6d6d6;
      }
    }
  }

  .grid__btn {
    margin-top: 32px;
    width: 100%;
    height: 56px;
    border: 1px solid #c1c1c1;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease-in-out;

    p {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin: 0;
      font-family: $font-primary;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 16px;
      text-align: center;
      color: $color-dark;
    }
  }

  .grid__btn:hover {
    background-color: $color-dark;

    p {
      color: #c1c1c1;
    }
  }
}

.filter__letters li {
  transition: 0.3s;
}

.filter__letters li:hover label {
  color: #ccad7b;
}

@media (max-width: 1300px) {
  .brands__grid {
    .grid__list {
      .grid__list__item {
        height: 250px;
        width: 250px;
      }
    }
  }
}

@media (max-width: 1150px) {
  .brands__grid {
    margin: 0 40px 40px;

    .grid__list {
      gap: 16px;

      .grid__list__item {
        height: 235px;
        width: 235px;
      }
    }
  }
}

@media (max-width: 1000px) {
  .brands__content {
    display: block;
    padding: 0;
  }
  .grid__list {
    padding: 0 16px;
  }
  .grid__list__item {
    justify-content: space-between;
    border-bottom: 1px solid #D6D6D6;
    width: 100%;
  }
  .grid__list__item__image {
    width: 200px;
    height: 112px !important;
    flex: unset !important;
  }
  .grid__list__item__title {
    span {
      color: #888888 !important;
    }
  }
  //filter
  .brands__filter {
    // }
    .filter__mobile {
      display: block;

      .filter__mobile__btn {
        display: flex;
      }
    }

    .filter__desktop {
      display: none;
    }
  }
  // Brands grid mobile
  .brands__grid {
    margin: 0 0 40px;
    // grid header mobile
    .grid__header {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      margin: 32px auto;

      .grid__header__text {
        font-family: $font-secondary;
        margin: 0 20px;
      }

      .grid__header__decoration {
        width: 100%;
        height: 40px;

        .first {
          display: block;
        }

        .second {
          margin-left: 32px;
        }

        .third {
          margin-left: 40px;
        }

        .fourth {
          margin-left: 56px;
        }
      }
    }

    // grid list mobile
    .grid__list {
      grid-template-columns: repeat(1, 1fr);
      gap: 0;

      .grid__list__item {
        height: 100%;
        width: 100%;
        flex-direction: row;
        align-items: baseline;
        justify-content: space-between;
        background-color: transparent;
        //padding: 0 24px;

        .grid__list__item__image {
          display: flex;
          align-items: center;
          height: 200px;
          // margin: 20px 40px;
          margin: 16px;
          width: 150px;
        }

        .grid__list__item__title {
          position: relative;
          bottom: 0;
          margin: auto 0;
          text-align: end;
        }
      }

      .grid__list__item:not(:last-child) .grid__list__item__dash {
        display: block;
      }
    }

    .grid__btn {
      margin: 32px auto;
      width: 90%;
    }
  }
}

@media (max-width: 768px) {
  .brands__hero {
    img {
      height: 300px;
    }

    h1 {
      position: absolute;
      top: 50%;
      left: 15%;
      font-size: 36px;
      line-height: 55px;
    }
  }
  .brands__tab__navigation {
    .navigation__item {
      margin-left: 32px;
    }
  }
}

@media (max-width: 375px) {
  .brands__grid {
    .grid__list {
      .grid__list__item {
        padding: 0;
        padding-right: 16px;
      }
    }
  }
}
</style>
