<template>
  <default-layout>
    <Menu :color="(scrolled)? 'scrolledClass':'notScrolled'"/>
    <div class="default__container">
      <div class="banner__bg" :style="{'background': `url('${bg}')`}">
        <div class="bg__content">
          {{ welcomePageTitle.value }}
        </div>
      </div>
      <div class="mainPageContainer" v-if="info.length">
        <div class="container__item">
          <div class="item__block1">
            <div class="blockBg">
              <div class="blockImage" :style="{backgroundImage: `url(${info[0].img})`}">
              </div>
            </div>
            <div class="block__floatRight">
              <div class="block__content">
                <h2 id="brandTitle">{{ info[0].title }}</h2>
                <span>{{ info[0].subtitle }}</span>
                <p>{{ info[0].main_text }}</p>
                <button class="basic__btn"><a href="/brand/3">{{ learnMoreBtnText }}</a></button>
              </div>
              <div class="block__orangeRectangle">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
          <div class="item__block2">
            <div class="block__orangeRectangleAboutCompany">
              <div></div>
              <div class="second__rec"></div>
              <div></div>
              <div>
                <div class="block__orangeBg">
                  <h2 class="block__contentHeader">{{ info[1].title }}</h2>
                </div>
                <div class="block__imgInRectangle" :style="{backgroundImage: `url('${info[1].img}')`}"></div>
              </div>
            </div>
            <div class="block__contentAboutCompany">
              <span>{{ info[1].subtitle }}</span>
              <p>{{ info[1].main_text }}</p>
            </div>
          </div>
        </div>
        <div class="container__item">
          <div class="item__block3">
            <div class="block3__flexEnd">
              <div class="block3__content">
                <h2>{{ info[2].title }}</h2>
                <span>{{ info[2].subtitle }}</span>
                <p>{{ info[2].main_text }}</p>
                <button class="basic__btn"><a href="/about#philosophy-company">{{ learnMoreBtnText }}</a></button>
              </div>
            </div>
            <div class="block3__parent">
              <div class="parent__orangeBackground"></div>
              <div class="parent__image" :style="{backgroundImage: `url('${info[2].img}')`}"></div>
            </div>
          </div>
          <div class="item__block4">
            <div class="limit__buttonContainer">
              <button class="basic__btn"><a href="/about">{{ learnMoreBtnText }}</a></button>
            </div>
            <div class="block4__parent">
              <div class="block4__orangeBackground"></div>
              <div class="block4__image" :style="{backgroundImage: `url('${info[3].img}')`}"></div>
            </div>
            <div class="block4__content">
              <h2>{{ info[3].title }}</h2>
              <span>{{ info[3].subtitle }}</span>
              <p>{{ info[3].main_text }}</p>
              <button class="basic__btn"><a href="/brand/3">{{ learnMoreBtnText }}</a></button>
            </div>
          </div>
        </div>
      </div>
      <div class="limit__container" id="events">
        <div class="event-grid-container" id='events-container'>
          <div class="event-flex-title">
            <div class="flex-title-item">{{ eventsText }}</div>
            <div class="flex-title-item">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div class="event-grid-box" v-if="events.length">
            <div class="event-grid-item">
              <a class="render-link" :href="'/events/' + events[0].id">
                <div class="item-bg-img-block1" :style="{backgroundImage: `url('${events[0].img}')`}"></div>
              </a>
              <div class="grid-item1-content">
                <div class="grid-item-rec-parent">
                  <div class="grid-item-rec"></div>
                  <div class="grid-item-rec"></div>
                  <div class="grid-item-rec"></div>
                </div>
                <div class="grid-item-title">
                  <a class="render-link" :href="'/events/' + events[0].id">
                    {{ events[0].title }}
                  </a>
                </div>
              </div>
            </div>
            <div class="event-grid-container">
              <div class="event-grid-item flex-grid-item">
                <div class="grid-flex-tx">
                  <a class="render-link" :href="'/events/' + events[2].id">
                    <div class="item-bg-img-block2a" :style="{backgroundImage: `url('${events[2].img}')`}"></div>
                  </a>
                  <div class="grid-item1-content">
                    <div class="flex-item-rec"></div>
                    <div class="content">
                      <a class="render-link" :href="'/events/' + events[2].id">
                        {{ events[2].title }}
                      </a>
                    </div>
                  </div>
                </div>
                <div class="grid-flex-tx">
                  <a class="render-link" :href="'/events/' + events[3].id">
                    <div class="item-bg-img-block2b" :style="{backgroundImage: `url('${events[3].img}')`}"></div>
                  </a>
                  <div class="grid-item1-content">
                    <div class="flex-item-rec"></div>
                    <div class="content">
                      <a class="render-link" :href="'/events/' + events[3].id">
                        {{ events[3].title }}
                      </a>
                    </div>
                  </div>
                </div>

              </div>
              <div class="event-grid-item">
                <a class="render-link" :href="'/events/' + events[4].id">
                  <div class="item-bg-img-block3" :style="{backgroundImage: `url('${events[4].img}')`}"></div>
                </a>
                <div class="grid-item1-content">
                  <div class="grid-item-rec-parent">
                    <div class="grid-item-rec"></div>
                    <div class="grid-item-rec"></div>
                    <div class="grid-item-rec"></div>
                  </div>
                  <div class="grid-item-title">
                    <a class="render-link" :href="'/events/' + events[4].id">
                      {{ events[4].title }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="event-grid-item grid-block4">
              <a class="render-link" :href="'/events/' + events[1].id">
                <div class="item-bg-img-block4" :style="{backgroundImage: `url('${events[1].img}')`}"></div>
              </a>
              <div class="grid-item1-content">
                <div class="grid-item-rec-parent">
                  <div class="grid-item-rec"></div>
                  <div class="grid-item-rec"></div>
                  <div class="grid-item-rec"></div>
                </div>
                <div class="grid-item-title">
                  <a class="render-link" :href="'/events/' + events[1].id">
                    {{ events[1].title }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section class="brut">
          <div class="side-img"></div>
          <div class="content-center">
            <div class="content-center-company-name"></div>
            <div class="content-content">{{ brutText.value }}
            </div>
            <div class="content-button"><a href="https://www.brut.kz">{{ redirectText }}</a></div>
          </div>
          <div class="side-img"></div>
        </section>
      </div>
    </div>
  </default-layout>
</template>

<script>
import Menu from "../components/Menu";
import axios from "axios";
import base__url from '../config'

export default {
  name: 'Home',
  components: {
    "Menu": Menu,
  },
  data() {
    return {
      info: [],
      hash: this.$route.hash,
      events: [],
      learnMoreBtnText: '',
      windowWidth: window.innerWidth,
      scrolled: false,
      welcomePageTitle: {},
      eventsText: '',
      redirectText: '',
      brutText: '',
      bannerImage: ''
    }
  },

  watch: {
    windowWidth(newValue, oldValue) {
      this.txt = `it changed to ${newValue} from ${oldValue}`;
    }
  },

  mounted() {
    window.setTimeout(this.scrollToAnchorPoint, 500)
    axios.get('/api/events').then(response => (this.events = response.data.events));
    axios.get('/api/welcome-section').then(response => (this.info = response.data.sections));
    axios.get('/api/settings/welcome.subtitle').then(response => (this.welcomePageTitle = response.data.setting));
    axios.get('/api/settings/welcome.learn').then(r => (this.learnMoreBtnText = r.data.setting.value))
    axios.get('/api/settings/welcome.events').then(r => (this.eventsText = r.data.setting.value))
    axios.get('/api/settings/welcome.redirect').then(r => (this.redirectText = r.data.setting.value))

    axios.get('/api/banners').then(r => (this.bannerImage = r.data.banners))
    axios.get('/api/settings/brut.text').then(r => (this.brutText = r.data.setting))
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  computed: {
    bg: function () {
      if (this.bannerImage.length) {
        if (this.windowWidth < 376) {
          return this.bannerImage[0].image_mobile
        } else if (this.windowWidth > 376 && this.windowWidth < 800) {
          return this.bannerImage[0].image_laptop
        } else {
          return this.bannerImage[0].image
        }
      } else {
        return '/images/home/banner.jpg'
      }
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    scrollToAnchorPoint() {
      if (this.$route.hash) {
        let container = document.getElementById('events-container').offsetTop
        window.scroll({left: 0, top: container, behavior: 'smooth'})
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth
    },
    handleScroll() {
      this.scrolled = window.scrollY > 100;
    }
  }
}
</script>
<style scoped lang="scss">
@import "src/sass/variables";

* {
  font-family: $font-primary;
}

.default__container {
  //overflow: hidden;
}

.banner__bg {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  height: 100vh;
}

.bg__content {
  max-width: 872px;
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #FFFFFF;
}

.limit__container {
  max-width: 1440px;
  margin: 0 auto;
}

/*-------------------First Flexbox Container-------------------------------*/

.container__item {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.item__block1, .item__block2 {
  flex-grow: 1;
  // flex-basis: 0;
}
@media screen and(min-width: 910px){
  .item__block1, .item__block2, .item__block3, .item__block4{
    width: 50% !important;
  }
}
.item__block3, .item__block4 {
  flex-grow: 1;
  // flex-basis: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.grid-item-title a, .grid-item1-content .content a {
  transition: 0.3s;
}

.grid-item-title a:hover, .grid-item1-content .content a:hover {
  color: #CCAD7B;
}

.grid-item-title a, .grid-item1-content .content a {
  font-family: $font-secondary;
}

.content-center .content-content {
  font-family: $font-secondary;
}

.item__block1 {
  margin-right: 39px;
}

.item__block2 {
  margin-left: 39px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.blockBg {
  background: #CCAD7B;
  height: 529px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.blockImage {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 433px;
  width: 605px;
}

.block__floatRight {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.block__content {
  max-width: 605px;
  width: 100%;

}

h2 {
  margin-top: 32px;
  font-style: normal;
  font-weight: 250;
  font-size: 30px;
  line-height: 30px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #010101;
}

span {
  margin-top: 32px;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #A2A2A2;
}

p {
  margin-top: 16px;
  max-width: 558px;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #010101;
}

button {
  margin: 56px 0 0 0;
  cursor: pointer;
  height: 56px;
  width: 320px;
  background: white;
  text-align: center;
  border: 1px solid #C1C1C1;
  border-radius: 2px;
  font-weight: 300;
  font-size: 16px;
  line-height: 16px;
  color: #010101;
}

.block__orangeRectangle {
  margin-top: 56px;
  display: flex;
  flex-direction: row;
  height: 64px;
  max-width: 605px;
  width: 100%;
  justify-content: space-between;
}

.block__orangeRectangle div {
  background: #CCAD7B;
  height: 100%;
}

.block__orangeRectangle div:nth-child(1) {
  width: 3%;
}

.block__orangeRectangle div:nth-child(2) {
  width: 5%;
}

.block__orangeRectangle div:nth-child(3) {
  width: 7%;
}

.block__orangeRectangle div:nth-child(4) {
  width: 9%;
}

.block__orangeRectangle div:nth-child(5) {
  width: 65%;
}

.block__orangeRectangleAboutCompany {
  margin-top: 85px;
  margin-left: 99px;
  display: flex;
  flex-direction: column;
  height: 721px;
  justify-content: space-between;
}

.block__orangeRectangleAboutCompany div:nth-child(1) {
  height: 2.3%;
  background: #CCAD7B;
}

.block__orangeRectangleAboutCompany div:nth-child(2) {
  height: 4.6%;
}

.block__orangeRectangleAboutCompany div:nth-child(3) {
  background: #C49955;
  height: 8.5%;
}

.block__orangeRectangleAboutCompany div:nth-child(4) {
  height: 77%;
  position: relative;
}

.block__orangeBg {
  height: 100% !important;
  display: flex;
  align-items: flex-end;
  width: 100%;
  position: relative;
  z-index: 1;
}

.block__imgInRectangle {
  background-position: center !important;
  background-repeat: no-repeat !important;
  position: absolute;
  z-index: 3;
  margin-top: 26px;
  top: 57px;
  left: -99px;
  height: 75% !important;
  width: 597px;
}

.block__contentHeader {
  max-width: 497px;
  width: 100%;
  text-align: right;
  font-weight: 250;
  font-size: 30px;
  line-height: 30px;
  color: black;
}

.block__contentAboutCompany {
  max-width: 597px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  text-align: right;
  flex-direction: column;
}

.block__contentAboutCompany p {
  max-width: 490px;
  width: 100%;
  font-weight: 400;
  text-align: end;
  margin-bottom: 0;
}

.item__block3 {
  margin-right: 39px;
}

.item__block4 {
  margin-left: 39px;
}

.block3__flexEnd {
  display: flex;
  justify-content: flex-end;
}

.block3__content {
  max-width: 605px;
  width: 100%;
}

.block3__parent {
  margin-top: 72px;
  height: 900px;
  position: relative;
}

.parent__orangeBackground {
  position: relative;
  background: #CCAD7B;
  height: 88%;
}

.parent__image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  max-width: 605px;
  width: 100%;
}

.limit__buttonContainer {
  max-width: 605px;
  width: 100%;
  height: max-content;
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.block__content h2 {
  font-family: $font-primary;
}

.block__content span, .block__content p, .block__contentAboutCompany span, .block__contentAboutCompany p,
.block3__content span, .block3__content p, .block4__content span, .block4__content p {
  font-family: $font-secondary;
}

@media screen and(min-width: 990px) {
  .second__rec{
    background: #CCAD7B;
  }
  .block4__content {
    span {
      margin: 0;
    }

    h2 {
      margin: -8px 0 32px 0;
    }
  }
}

.block4__parent {
  margin-top: 56px;
  height: 795px;
  position: relative;
}

.block4__orangeBackground {
  background: #CCAD7B;
  height: 88%;
}

.block4__image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  max-width: 605px;
  width: 100%;
}

.block4__content {
  max-width: 605px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  text-align: end;
}

.block4__content p {
  max-width: 401px;
  width: 100%;
}

@media screen and (max-width: 450px) {
  .block__content, .limit__buttonContainer, .block4__content, .block3__content {
    button {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1330px) {
  .item__block1, .item__block3 {
    margin-right: 25px;
  }

  .item__block2, .item__block4 {
    margin-left: 25px;
  }

  .blockImage, .block4__image {
    width: 484px;
  }

  .block__content, .block4__content, .limit__buttonContainer, .block__orangeRectangle {
    max-width: 484px;
  }

  .block__imgInRectangle, .block__contentAboutCompany {
    width: 477px;
  }

  .block__imgInRectangle {
    top: 32px;
  }

  .block__contentHeader {
    max-width: 378px;
  }

  .block__orangeRectangleAboutCompany {
    height: 552px;
  }

  .item__block2 {
    justify-content: space-around;
  }

  .block3__parent {
    height: 720px;
  }

  .block3__content, .parent__image {
    max-width: 484px;
  }

  .block4__parent {
    height: 636px;
  }
}

@media screen and (max-width: 1070px) {
  .default__container {
    margin-top: -1px;
  }
  .blockBg {
    height: 423px;
  }

  .blockImage {
    width: 415px;
    height: 368px;
  }

  .block__content, .block__orangeRectangle, .block3__content, .parent__image {
    width: 415px;
  }

  .block3__parent {
    height: 612px;
  }

  .block__orangeRectangleAboutCompany {
    margin-top: 0;
  }

  .block__imgInRectangle, .block__contentAboutCompany, .limit__buttonContainer, .block4__image, .block4__content {
    width: 405px;
  }

  .block__contentHeader {
    max-width: 308px;
  }

  .limit__buttonContainer button {
    margin-top: 5px;
  }
}

.block__contentAboutCompany span {
  margin: 0;
}

.basic__btn {
  a {
    font-family: $font-primary;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-weight: 300;
    font-size: 16px;
    line-height: 16px;
    text-decoration: none;
    color: #010101;
  }
}

@media screen and (max-width: 905px) {
  .item__block1, .item__block3 {
    margin-right: 0;
  }

  .item__block2, .item__block4, .block__orangeRectangleAboutCompany {
    margin-left: 0;
  }

  .container__item {
    flex-direction: column;
  }

  .blockBg, .block__floatRight, .item__block3 {
    justify-content: center;
    align-items: center;
  }

  .blockImage {
    width: 500px;
    height: 395px;
  }

  .block__content {
    max-width: 500px;
    width: 100%;
  }

  .block__orangeRectangle {
    display: none;
  }

  .block__orangeRectangleAboutCompany {
    margin-top: 56px;
  }

  .block__imgInRectangle {
    top: 30%;
    left: 50%;
    transform: translate(-50%, -30%);
    width: 500px;
  }

  .block__orangeBg {
    padding-bottom: 15px;
  }

  .block__contentHeader, .block__contentAboutCompany {
    max-width: 500px;
    width: 100%;
    text-align: start;
    margin: 0 auto;
  }

  .block__contentAboutCompany p {
    text-align: start;
  }

  .block__contentAboutCompany {
    align-items: unset;
  }

  .block3__flexEnd {
    width: 500px;
    justify-content: start;
  }

  .block3__content {
    width: 100%;
  }

  .basic__btn {
    margin: 20px auto;
  }

  .block3__parent {
    width: 100%;
    height: 660px;
  }

  .parent__orangeBackground {
    width: 100%;
  }

  .parent__image, .block4__image {
    max-width: 550px;
    width: 500px;
    left: 50%;
    transform: translateX(-50%);
  }

  .limit__buttonContainer {
    margin: 0 auto;
    max-width: 550px;
    width: 500px;
    justify-content: start;
    align-items: start;
  }

  .limit__buttonContainer .basic__btn {
    margin: 42px 0;
  }

  .block4__content {
    justify-content: unset;
    align-items: unset;
    text-align: unset;
    max-width: 550px;
    margin: 0 auto !important;
    width: 500px;
  }

  .block4__content button {
    margin: 32px 0 0 0;
  }

  .item__block3, .block3__flexEnd {
    order: 2;
  }

  .item__block4, .block3__parent {
    order: 1;
  }

  .block4__parent {
    margin-top: 0;
  }

}

@media screen and (max-width: 520px) {
  .blockImage {
    margin-top: 8px;
    width: 359px;
    height: 433px;
  }

  .blockBg {
    height: max-content;
  }

  .block__content, .block__imgInRectangle,
  .block__contentHeader, .block__contentAboutCompany,
  .limit__buttonContainer, .block4__image,
  .block4__content, .block3__flexEnd, .parent__image {
    width: 359px;
  }
  .block__imgInRectangle {
    height: 355px !important;
  }
  .block4__image {
    height: 544px;
  }
  .parent__image {
    height: 532px;
  }
  .block3__content h2 {
    margin: 32px 0;
  }
  .block__imgInRectangle {
    top: 10%;
    transform: translate(-50%, -10%);
  }
}

@media screen and (max-width: 375px) {
  .block__orangeRectangleAboutCompany {
    height: max-content;
  }
  .block__content button, .basic__btn {
    width: 100%;
  }
  .block__orangeRectangleAboutCompany div:nth-child(1) {
    height: 10px;
    margin-bottom: 11px;
  }
  .block__orangeRectangleAboutCompany div:nth-child(2) {
    height: 21px;
    margin-bottom: 10px;
  }
  .block__orangeRectangleAboutCompany div:nth-child(3) {
    height: 38px;
    margin-bottom: 11px;
  }
  .block__orangeRectangleAboutCompany div:nth-child(4) {
    height: 355px;
  }
  .block4__parent, .block3__parent {
    height: 555px;
  }
  .block4__orangeBackground {
    height: 340px;
    margin-left: 20px;
  }
  .parent__orangeBackground {
    height: 340px;
    width: 340px;
  }
}

@media screen and (max-width: 360px) {
  .blockImage, .block__imgInRectangle {
    margin-top: 8px;
    width: 310px;
    height: 298px;
  }
  .block4__image, .parent__image,
  .block__content, .block__contentAboutCompany,
  .block__contentHeader, .limit__buttonContainer, .block4__content, .block3__flexEnd {
    width: 310px;
  }

  .basic__btn {
    max-width: 310px;
    width: 100%;
  }
}

@media screen and (max-width: 320px) {
  .limit__buttonContainer {
    padding: 0 12px;
    margin: 0 auto;

    .basic__btn {
      padding: 0 !important;
    }
  }
  .block4__content {
    width: 280px;

    .basic__btn {
      padding: 0;
      margin-left: 0;
      width: 100% !important;
    }
  }
  .block3__content {
    .basic__btn {
      padding: 0;
      margin-left: 0;
      width: 100% !important;
    }
  }
  .block__contentAboutCompany {
    width: 280px !important;
    padding: 0 !important;

    p {
      width: 100% !important;
      padding-right: 0 !important;
    }
  }
  .block__contentHeader {
    width: 280px;
  }
  .limit__buttonContainer {
    .basic__btn {
      width: 280px;
    }
  }
}


/*------------------Second Grid Container----------------------------------*/

.event-flex-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin: 50px 5.5%;
  /**/
}

.flex-title-item:first-child {
  width: 20%;
  height: 64px;
  margin-right: 40px;
  font-family: $font-primary;
  font-weight: 250;
  font-size: 30px;
  line-height: 64px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #010101;
}

.flex-title-item:last-child {
  width: 80%;
  height: 64px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flex-title-item:last-child div {
  height: 100%;
  background: #CCAD7B;
}

.flex-title-item:last-child div:nth-child(1) {
  width: 2.6%;
}

.flex-title-item:last-child div:nth-child(2) {
  width: 6.1%;
}

.flex-title-item:last-child div:nth-child(3) {
  width: 6.5%;
}

.flex-title-item:last-child div:nth-child(4) {
  width: 9.2%;
}

.flex-title-item:last-child div:nth-child(5) {
  width: 65.6%;
}

.event-grid-box {
  margin: 5px 5.6%;
  height: auto;
  display: grid;
  grid-template-areas: "eventGrid1 event__cont" "eventGrid2 event__cont";
  grid-template-rows: 2fr 1fr;
  grid-template-columns: 1fr 1fr;
  /**/
  gap: 56px 78px;
}

.event-grid-container {
  grid-area: event__cont;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /*display: grid;*/
  /*grid-template-columns: 1fr;*/
  /*grid-auto-rows: minmax(min-content, max-content) 1fr;*/
  /**/
}

.item-bg-img-block1 {
  //background: url("../../public/images/home/ladyGaga.jpg") no-repeat center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 974px;
}

//.render-link {
//  font-weight: normal;
//  font-size: 16px;
//  line-height: 26px;
//  color: #010101;
//}

.flex-grid-item {
  flex: 1 1 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.grid-flex-tx {
  margin-bottom: 40px;
  width: 45%;
  height: max-content;
}

.grid-flex-tx:nth-child(1) {
  align-self: flex-start;
  justify-self: flex-start;
}

.grid-flex-tx:nth-child(2) {
  align-self: end;
  justify-self: end;
}

.item-bg-img-block2a {
  width: 100%;
  //background: url("../../public/images/home/riedel1.jpg") no-repeat center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 410px;
}

.item-bg-img-block2b {
  width: 100%;
  //background: url("../../public/images/home/riedel2.jpg") no-repeat center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 410px;
}

.item-bg-img-block3 {
  //background: url("../../public/images/home/ardbeg.jpg") no-repeat center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 874px;
}

.grid-block4 {
  display: flex;
  flex-direction: column;
}

.item-bg-img-block4 {
  flex: 1 1 auto;
  //background: url("../../public/images/home/asia-awards.jpg") no-repeat center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 438px;
}

.grid-item1-content {
  margin-top: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: inherit;
  height: 40px;
}

.render-link {
  font-weight: normal;
  font-size: 16px;
  text-decoration: none;
  line-height: 26px;
  color: #010101;

  &:hover {
    text-decoration: none;
  }
}

.grid-item-rec-parent {
  display: flex;
  flex-direction: row;
  height: 40px;
  width: 96px;
  /*border: 1px solid red;*/
}

.grid-item1-content {
  width: 100%;
  height: 52px;
  /*border: 1px solid blue;*/
}

.flex-item-rec {
  height: 30px;
  background: #C49955;
  margin-right: 16px;
  width: 8px;
}

.grid-item-rec {
  background: #CCAD7B;
  margin-right: 16px;
}

.grid-item-rec:nth-child(1) {
  width: 30px;
}

.grid-item-rec:nth-child(2) {
  width: 16px;
}

.grid-item-rec:nth-child(3) {
  width: 8px;
}

/*-----------------Brut Company Logo Style---------------------------------*/
.brut {
  margin: 120px 5.6% 80px 5.6%;
  height: 90px;
  background: #C49955;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.side-img {
  background: url('../../public/images/home/ABrut img.svg') no-repeat center;
  background-size: contain;
  width: 100px;
  height: 100%;
}

.content-center {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.content-center-company-name {
  background: url("../../public/images/home/brut logo.svg") no-repeat center;
  background-size: contain;
  height: 100%;
  width: 256px;
}

.content-content {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  max-width: 391px;
  color: #FFFFFF;
}

.content-button {
  cursor: pointer;
  transition: ease 0.2s;
  width: 320px;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 2px;
  height: 70%;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-family: $font-primary;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    text-decoration: none;
    color: #FFFFFF;
  }
}

.content-button:hover {
  background: white;
  border: 1px solid $color-brown;

  a {
    color: $color-brown;
  }
}

/*-----------------Media Query---------------------------------------------*/
@media screen and (max-width: 1220px) {
  .brut {
    position: relative;
    padding: 32px 16px;
    box-sizing: border-box;
    height: 295px;
  }

  .content-center {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .content-center-company-name {
    height: 56px;
  }

  .content-content {
    max-width: 327px;

  }

  .content-button {
    height: 56px;
    max-width: 343px;
  }

  .side-img {
    display: none;
  }

}

@media screen and (max-width: 1165px) {
  //.flex-title-item:last-child div {
  //  height: 40px;
  //}

  .splited-blocks div {
    height: 40px;
  }

  .event-grid-box {
    gap: 56px 30px !important;
  }

  .grid-flex-tx {
    width: 48%;
  }
}

@media screen and (max-width: 996px) {
  .event-flex-title {
    margin: 32px 0;
    flex-direction: column;
    align-items: unset;
  }

  .flex-title-item:first-child {
    width: 200px;
    margin-top: 20px;
    text-align: center;
    order: 2;
  }

  .flex-title-item:last-child {
    width: 100%;
    order: 1;
    height: 40px;
  }
}

@media screen and (max-width: 900px) {
  .banner__bg .bg__content {
    width: 279px;
    height: 216px;
    font-size: 16px;
  }
  .grid__container {
    grid-template-areas: "block1" "cont__cont" "block4";
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
  }

  .block-bg-wine-img {
    padding-top: 0;
  }

  .wine-item-bg {
    top: 0;
  }

  .block4-item {
    margin: 16px;
  }

  .block4-item button {
    margin-bottom: 56px;
  }

  .block4-item:nth-child(1) {
    order: 1
  }

  .block4-item:nth-child(2) {
    order: 3
  }

  .block4-item:nth-child(3) {
    order: 2
  }

  .block-bg-orange {
    padding-left: 2%;
    padding-top: 2%;
  }

  .left-side {
    padding: 16px;
  }

  .block-content p {
    /*margin: 0;*/
    max-width: unset;
  }

  .block-content button {
    width: 100%;
  }

  .block-absolute-img {
    padding-top: unset;
  }

  .block-absolute-img .block-rectangle {
    height: 60%;
  }

  .block-absolute-img .block-rectangle {
    height: 100%;
  }

  .block-absolute-img .block-rectangle div {
    margin-left: unset;
  }

  .block-absolute-img .block-rectangle div:nth-child(1) {
    height: 3.2%;
  }

  .block-absolute-img .block-rectangle div:nth-child(2) {
    height: 5.5%;
  }

  .block-absolute-img .block-rectangle div:nth-child(4 ) {
    height: 75%;
  }

  .block-absolute-img .block-img {
    height: 75%;
    //width: 96%;
    margin: 2% 2% 0 2%;
  }

  .right-side {
    align-items: flex-start;
  }

  .container__block .right-side h2,
  .container__block .right-side span {
    text-align: left;
    width: 100%;
    padding-left: 2%;
    padding-right: 2%;
  }

  .container__block .right-side button, .container__block .right-side p {
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
  }

  .container__block .right-side p {
    text-align: left;
  }

  .block3-fItem:nth-child(1) {
    margin-left: 2%;
  }

  .fItem-bg-img {
    width: 98%;
  }

  .block-bg-wine-img {
    margin-right: 2%;
  }

  .wine-item-bg-img {
    margin-left: 2%;
    /*height: 530px;*/
  }

  .limited-content-block {
    margin: 0;
  }


  /*    -----------------Second Grid Container --------------------------*/
  .event-grid-box {
    grid-template-areas: "eventGrid1" "event__cont" "eventGrid2";
    grid-template-rows: max-content max-content max-content;
    grid-template-columns: 1fr;
    /**/
  }

  .item-bg-img-block1, .item-bg-img-block3, .item-bg-img-block4 {
    height: 537px;
  }


  .flex-grid-item {
    justify-content: space-between;
    flex-direction: column;
  }

  .grid-flex-tx {
    width: 100%;
  }
}

@media screen and (width: 768px) {
  .flex-title-item {
    &:first-child {
      margin-left: 30px;
    }
  }
}

@media screen and (width: 425px) {
  .event-flex-title {
    margin-bottom: 0;
  }
  .flex-title-item {
    &:first-child {
      margin: 32px 0 32px 26px;

      height: max-content;
      line-height: unset;
      text-align: left;
    }
  }
}

#brandTitle {
  margin: 32px 0;
}

@media screen and (max-width: 500px) {
  .block4__parent {
    height: 548px;
  }
  .block__imgInRectangle {
    margin-top: 0;
    height: 341px !important;
  }
  .block__orangeRectangleAboutCompany div:nth-child(2) {
    background: #CCAD7B;
  }
  .block__orangeBg {
    .block__contentHeader {
      margin-bottom: 5px;
      padding-bottom: 0;
    }
  }
  .block__contentAboutCompany span {
    margin-top: 12px;
  }
  .block4__content {
    span {
      margin: 0;
    }

    h2 {
      margin: 32px 0;
    }
  }
  .block3__parent {
    height: 534px;
  }
  .block__contentHeader {
    padding-bottom: 20px;
  }

}

.block3__content h2 {
  margin: 32px 0;
}

@media screen and (max-width: 375px) {
  .event-flex-title {
    margin-bottom: 0;
  }
  .flex-title-item {
    &:first-child {
      margin: 32px 0 32px 8px;

      height: max-content;
      line-height: unset;
      text-align: left;
    }
  }
  .event-grid-box {
    margin: 5px 8px;
  }
  .brut {
    margin: 120px 8px 80px 8px;

    .content-button {
      max-width: 327px;
      width: 100%;
    }
  }
  .block-bg-orange {
    height: 443px;
  }
  .block-bg-img {
    width: 359px;
    height: 433px;
  }
  .block-absolute-img {
    height: 393px;
  }
  .block-img {
    width: 359px !important;
    height: 277px !important;
  }
  .block-bg-wine-img {
    height: 400px;
  }
  .block__orangeRectangleAboutCompany {
    div:last-child {
      height: 419px;
    }
  }
}

@media screen and (max-width: 320px) {
  .parent__orangeBackground, .block__contentAboutCompany {
    width: 320px;
  }
  .block__imgInRectangle, .block4__image, .parent__image {
    width: 290px;
  }
  .block__contentAboutCompany p {
    width: 290px;
  }
  .block__content, .block__contentAboutCompany, .block__orangeBg, .block3__content {
    padding-left: 15px;
    padding-right: 15px;
  }

  .block__contentAboutCompany span {
    margin-left: 0;
  }
  .block__contentAboutCompany p {
    margin-left: 0;
    padding-right: 15px;
    width: 300px;
    margin-right: 0;
  }
  .block__content button, .block4__content button, .block3__content button {
    width: 290px !important;
  }
  .block4__content button {
    margin-left: 10px;
  }

}

@media screen and (max-width: 540px) {
  .event-grid-box {
    display: flex;
    flex-direction: column;
  }
  .event-grid-container, .event-grid-item, .grid-block4 {
    grid-area: unset;
  }
  .grid-block4 {
    height: auto;

    .item-bg-img-block4 {
      height: 242px;
    }
  }
  .item-bg-img-block3 {
    height: 478px
  }
  .brut {
    margin-top: 56px;
  }
}
</style>