<template>
  <div class="services__content">
    <div class="clients__description__section">
      <div class="description__block__left">
        <div class="block__left__background"></div>
        <img :src="info.main_img" alt=""/>
      </div>
      <div class="description__block__right" v-html="info.main_text">

      </div>
    </div>
    <div v-if="info.makeOrder">
      <div class="clients__consultation__section">
        <div class="consultation__block__left">
          <div class="block__left__title">
            {{ consult }}
          </div>
          <div class="block__left__description">
            {{ info.manager_subtitle }}
          </div>
          <div class="block__left__profile">
            <div class="profile__image">
              <img :src="info.avatar" alt=""/>
            </div>
            <div class="profile__dash"></div>
            <div class="profile__information">
              <div class="profile__information__name">{{ info.name }}</div>
              <div class="profile__information__quote">
                {{ info.citation }}
              </div>
              <a
                  href="#order-service"
                  class="profile__information__btn basic__btn"
              ><p>{{ makeOrderText }}</p></a
              >
            </div>
          </div>
          <div class="block__left__decoration">
            <div class="decoration__bar first"></div>
            <div class="decoration__bar second"></div>
            <div class="decoration__bar third"></div>
            <div class="decoration__bar fourth"></div>
          </div>
        </div>
        <div class="consultation__block__right">
          <div class="block__right__background"></div>
          <img :src="info.sec_img" alt=""/>
        </div>
      </div>
      <div class="clients__form__section" id="order-service">
        <div class="form__section__title">{{ requestText }}</div>
        <div class="form__section__dash"></div>
        <form class="form__section__form" @submit="createPromoCode">
          <input type="text" v-model="form.name" placeholder="ФИО" required/>
          <input type="text" v-model="form.phone" placeholder="Номер" required/>
          <div class="form__download__catalog">
            <div class="download__title">{{ partnerDownload.value }}</div>
            <div class="download__btn">
              <a target="_blank"
                 href="https://pma.kws.kz/storage/upload/sxf9FTqof7ISRJnOvXYXFDperDMzrcNlA7jwywEU.pdf">
                <svg
                    width="8"
                    height="13"
                    viewBox="0 0 8 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M3.64645 12.3536C3.84171 12.5488 4.15829 12.5488 4.35355 12.3536L7.53553 9.17157C7.7308 8.97631 7.7308 8.65973 7.53553 8.46447C7.34027 8.2692 7.02369 8.2692 6.82843 8.46447L4 11.2929L1.17157 8.46447C0.976311 8.2692 0.659729 8.2692 0.464466 8.46447C0.269204 8.65973 0.269204 8.97631 0.464466 9.17157L3.64645 12.3536ZM3.5 2.18558e-08L3.5 12L4.5 12L4.5 -2.18558e-08L3.5 2.18558e-08Z"
                      fill="currentColor"
                  />
                </svg>
              </a>
            </div>
          </div>
          <button class="form__send__btn basic__btn">{{ requestForm }}</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import base__url from "@/config";

export default {
  name: "ServicesContentClients",
  data() {
    return {
      info: {},
      partnerDownload: '',
      form: {
        name: '',
        phone: ''
      },
      requestForm: '',
      consult: '',
      makeOrderText: '',
      requestText: ''
    }
  },
  mounted() {
    axios.get('/api/partners').then(response => (this.info = response.data.partner[2]))
    axios.get('/api/settings/partners.download').then(re => (this.partnerDownload = re.data.setting))
    axios.get('/api/settings/partners.request_form').then(re => (this.requestForm = re.data.setting.value))
    axios.get('/api/settings/partners.request').then(re => (this.requestText = re.data.setting.value))
    axios.get('/api/settings/partners.consult').then(re => (this.consult = re.data.setting.value))
    axios.get('/api/settings/partners.order').then(re => (this.makeOrderText = re.data.setting.value))
  },
  methods: {
    createPromoCode() {
      axios.post('https://pma.kws.kz/api/mail/send', {
        name: this.form.name,
        phone: this.form.phone,
      }).then(response => {
        console.log(response['status']);
        if (response.status == 200) {
          // window.location.reload();
        }
      });
    }
  }
};
</script>

<style lang="scss">
@import "src/sass/variables";

.clients__description__section {
  margin: 120px 80px;
  display: flex;

  .description__block__left {
    position: relative;
    width: 50%;

    .block__left__background {
      position: absolute;
      top: -40px;
      left: -20%;
      width: 100%;
      height: 60%;
      background-color: $color-brown-easy;
    }

    img {
      position: relative;
      z-index: 1;
      object-fit: cover;
      width: 100%;
    }
  }
  .description__block__right {
    margin-left: 80px;
    width: 50%;

    //font settings
    line-height: 26px;
    font-size: 16px;

    p {
      margin: 32px 0;
    }

    .block__right__top__text {
      margin-bottom: 40px;

      p {
        font-family: $font-secondary;
      }

      .grey__text {
        color: #a1a1a1;
        margin-bottom: 32px;
      }
    }

    .block__right__bottom__text {
      margin: 56px 0;

      p {
        font-family: $font-secondary
      }

      .bottom__text__title {
        font-family: $font-primary;
        letter-spacing: 0.12em;
        text-transform: uppercase;
        font-weight: 300;
        font-size: 24px;
        line-height: 38px;
      }
    }

    .block__right__decoration {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 24px;
      background-color: $color-brown-easy;

      .decoration__bar {
        height: 100%;
        width: 18px;
        background-color: white;
      }

      .first {
        margin-left: 18px;
      }

      .second {
        margin-left: 36px;
      }

      .third {
        margin-left: 48px;
      }

      .fourth {
        margin-left: 64px;
      }
    }

    .reverse__decoration {
      flex-direction: row-reverse;

      .first {
        margin-left: 0;
        margin-right: 18px;
      }

      .second {
        margin-left: 0;
        margin-right: 36px;
      }

      .third {
        margin-left: 0;
        margin-right: 48px;
      }

      .fourth {
        margin-left: 0;
        margin-right: 64px;
      }
    }
  }
}

.clients__consultation__section {
  margin: 120px 80px;
  display: flex;
  justify-content: space-between;

  .consultation__block__left {
    width: 55%;

    .block__left__title {
      margin-bottom: 32px;
      font-family: $font-primary;
      letter-spacing: 0.12em;
      text-transform: uppercase;
      font-weight: 300;
      font-size: 24px;
      line-height: 38px;
    }

    .block__left__description {
      margin-bottom: 40px;
      font-family: $font-secondary !important;
    }

    .block__left__profile {
      display: flex;

      .profile__image {
        max-width: 180px;
        width: 100%;
        max-height: 180px;
        height: 100%;
        // clip-path: circle(60px at center);
        img {
          object-fit: cover;
          max-width: 180px;
          width: 100%;
          max-height: 180px;
          height: 100%;
          border-radius: 50%;
        }
      }

      .profile__dash {
        width: 1px;
        background-color: $color-brown-easy;
        margin: 0 32px;
      }

      .profile__information {
        display: flex;
        flex-direction: column;

        .profile__information__name {
          margin-bottom: 24px;
          font-family: $font-primary;
          letter-spacing: 0.12em;
          text-transform: uppercase;
          font-weight: 300;
          font-size: 24px;
        }

        .profile__information__quote {
          width: 75%;
          color: #747474;
          font-family: $font-secondary !important;
          font-size: 16px;
          line-height: 26px;
          margin-bottom: 32px;
        }

        .profile__information__btn {
          // cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 70%;
          height: 56px;
          text-decoration: none;
          color: unset;
          // border: 1px solid #c1c1c1;
          // border-radius: 2px;
          // transition: ease 0.2s;
          p {
            margin: 0;
            text-align: center;
            font-family: $font-primary;
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
          }
        }
      }
    }

    .block__left__decoration {
      display: flex;
      margin-top: 40px;
      height: 24px;
      width: 100%;
      background-color: $color-dark;

      .decoration__bar {
        background-color: white;
        height: 100%;
        width: 18px;
      }

      .first {
        margin-left: 18px;
      }

      .second {
        margin-left: 36px;
      }

      .third {
        margin-left: 45px;
      }

      .fourth {
        margin-left: 64px;
      }
    }
  }

  .consultation__block__right {
    position: relative;
    width: 35%;

    .block__right__background {
      position: absolute;
      bottom: -64px;
      right: -40%;
      width: 80%;
      height: 110%;
      background-color: $color-brown-easy;
    }

    img {
      position: relative;
      z-index: 1;
      object-fit: cover;
      width: 100%;
    }
  }
}

.clients__form__section {
  margin: 120px auto;

  .form__section__title {
    text-align: center;
    font-family: $font-primary;
    font-weight: 300;
    font-size: 24px;
    line-height: 40px;
    letter-spacing: 0.12em;
    text-transform: uppercase;
  }

  .form__section__dash {
    height: 1px;
    width: 160px;
    margin: 40px auto;
    background-color: $color-black;
  }

  .form__section__form {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 320px;
    font-family: $font-primary;

    input {
      height: 48px;
      margin-bottom: 16px;
      border: 1px solid #c1c1c1;
      border-radius: 2px;
      padding: 16px;
    }

    .form__download__catalog {
      height: 48px;
      display: flex;
      justify-content: space-between;

      .download__title {
        display: flex;
        align-items: center;
        padding: 12px;
        height: 100%;
        width: 256px;
        border: 1px solid #c1c1c1;
        color: #747474;
        border-radius: 2px;
      }

      .download__btn {
        cursor: pointer;
        transition: ease 0.2s;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 48px;
        border: 1px solid #c1c1c1;
        color: #747474;
        border-radius: 2px;

        a {
          text-decoration: none;
          color: black;
        }
      }

      .download__btn:hover {
        background-color: $color-dark;

        a {
          color: white;
        }
      }
    }

    .form__send__btn {
      margin-top: 32px;
      width: 100%;
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@media (max-width: 1000px) {
  .clients__description__section {
    flex-direction: column;
    margin: 56px 16px;

    .description__block__left {
      width: 100%;

      .block__left__background {
        top: -24px;
      }
    }

    .description__block__right {
      margin-top: 32px;
      margin-left: 0;
      width: 100%;
    }
  }
  .clients__consultation__section {
    margin: 56px 16px;
    flex-direction: column;

    .consultation__block__left {
      width: 100%;

      .block__left__profile {
        flex-direction: column;
        align-items: center;

        .profile__dash {
          width: 180px;
          height: 1px;
          margin: 32px 0;
        }

        .profile__information {
          align-items: center;
        }
      }
    }

    .consultation__block__right {
      width: 100%;
      margin: 32px 0;
    }
  }
}

@media (max-width: 426px) {
  .clients__description__section {
    .description__block__right {
      .block__right__decoration {
        .decoration__bar {
          width: 10px;
        }

        .first {
          margin-left: 9px;
        }

        .second {
          margin-left: 18px;
        }

        .third {
          margin-left: 24px;
        }

        .fourth {
          margin-left: 32px;
        }
      }

      .reverse__decoration {
        .first {
          margin-left: 0;
          margin-right: 9px;
        }

        .second {
          margin-left: 0;
          margin-right: 18px;
        }

        .third {
          margin-left: 0;
          margin-right: 24px;
        }

        .fourth {
          margin-left: 0;
          margin-right: 32px;
        }
      }
    }
  }

  .clients__consultation__section {
    .consultation__block__left {
      .block__left__profile {
        .profile__information {
          .profile__information__btn {
            width: 100%;
          }
        }
      }

      .block__left__decoration {
        margin-top: 32px;

        .decoration__bar {
          width: 9px;
        }

        .first {
          margin-left: 9px;
        }

        .second {
          margin-left: 18px;
        }

        .third {
          margin-left: 24px;
        }

        .fourth {
          margin-left: 32px;
        }
      }
    }
  }

  .clients__form__section {
    .form__section__form {
      width: 300px;
    }
  }
}
</style>