<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import sortCountryList from "./components/brands/BrandsContent"

export default {
  data() {
    return {
      hash: this.$route.hash,
    }
  },
  mounted() {
    this.$nextTick(function () {
      if (this.hash) {
        const refName = this.hash.replace('#', '')
        setTimeout(() => {
          this.scrollToAnchorPoint(refName)
        }, 100)
      }
    })
    sortCountryList('all')
  },
  methods: {
    scrollToAnchorPoint(refName) {
      const el = this.$refs[refName]
      el.scrollIntoView({behavior: 'smooth'})
    },
  }
}
</script>
<style lang="scss">
@import "src/sass/variables";

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  color: $color-black;
  font-family: $font-secondary;
  /* Keep footer at the bottom (stretch content to fit page) */
  // min-height: 100vh;
  // display: grid;
  // grid-template-rows: auto 1fr auto;
}

* {
  box-sizing: border-box;
}

.scroll__lock {
  overflow: hidden;
}

.relative__menu {
  height: 175px;
  position: relative;
  background: #242526;
}

.wrapper {
  padding: 0 40px;
  max-width: 1440px;
  margin: 0 auto;
}

@media screen and(max-width: 990px) {
  .relative__menu {
    display: none;
  }
}

@media (max-width: 768px) {
  .wrapper {
    padding: 0 16px;
  }
}

</style>
