<template>
  <div class="services__carousel">
    <div class="carousel__top">
      <button
          @click.prevent="slidePrev"
          class="services__carousel__btn btn__left"
      >
        <svg
            width="12"
            height="18"
            viewBox="0 0 12 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              d="M1 9.00024L11 17.3336"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
          />
          <path
              d="M0.999999 9.00007L11 0.666749"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
          />
        </svg>
      </button>

      <button
          @click.prevent="slideNext"
          class="services__carousel__btn btn__right"
      >
        <svg
            width="12"
            height="18"
            viewBox="0 0 12 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              d="M11 8.99976L1 0.666436"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
          />
          <path
              d="M11 8.99993L1 17.3333"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
          />
        </svg>
      </button>
      <div class="scroll__blocker blocker__left"></div>
      <div class="scroll__blocker blocker__right"></div>
      <hooper
          class="carousel__images"
          :settings="upperSettings"
          group="group1"
          ref="carousel"
      >
        <slide v-for="(item, index) in carouselItems" :key="index">
          <img :src="item.url" class="carousel_image__desktop" alt=""/>
          <img :src="index in carouselMobItems ? carouselMobItems[index].url : item.url" class="carousel_image__mobile" alt=""/>
        </slide>
      </hooper>
      <hooper
          class="carousel__text"
          group="group1"
          :autoPlay="false"
          :infiniteScroll="true"
          :wheelControl="false"
          :transition="0"
      >
        <slide v-for="(item, index) in presentationText.split(',')" :key="index">
          {{item}}
        </slide>
      </hooper>
    </div>
    <div class="carousel__bottom">
      <div class="scroll__blocker blocker__left"></div>
      <div class="scroll__blocker blocker__right"></div>
      <hooper :settings="bottomSettings" :autoPlay="true"
              :playSpeed="2000">
        <slide v-for="(item, index) in brandSliderItems" :key="index">
          <img :src="item.url" alt=""/>
        </slide>
        <hooper-progress slot="hooper-addons"></hooper-progress>
      </hooper>
    </div>
  </div>
</template>

<script>
import {Hooper, Slide, Progress as HooperProgress} from "hooper";
import "hooper/dist/hooper.css";
import axios from "axios";

export default {
  name: "ServicesCarousel",
  props: {
    carouselItems: [],
    carouselMobItems: [],
    brandSliderItems: [],
  },
  data() {
    return {
      presentationText: '',
      upperSettings: {
        itemsToShow: 1,
        infiniteScroll: true,
        centerMode: true,
        autoPlay: false,
        trimWhiteSpace: true,
        wheelControl: false,
        breakpoints: {
          1000: {
            itemsToShow: 5,
          },
          800: {
            itemsToShow: 3,
          },
          500: {
            itemsToShow: 2,
          },
        },
      },
      bottomSettings: {
        wheelControl: false,
        itemsToShow: 1,
        infiniteScroll: true,
        centerMode: true,
        trimWhiteSpace: true,
        breakpoints: {
          1000: {
            itemsToShow: 5,
          },
        },
      },
    };
  },
  async mounted() {
    axios.get('/api/partners/' + this.$route.params.id).then(response =>(this.presentationText = response.data.partner.image_titles))
  },
  components: {
    Hooper,
    Slide,
    HooperProgress,
  },
  methods: {
    slidePrev() {
      this.btnColorLeft = "white";
      this.btnColorRight = "#C49955";
      this.$refs.carousel.slidePrev();
    },
    slideNext() {
      this.btnColorLeft = "#C49955";
      this.btnColorRight = "white";
      this.$refs.carousel.slideNext();
    },
  },
};
</script>

<style lang="scss">
@import "src/sass/variables";

.services__carousel {
  max-width: 1600px;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -o-user-select: none !important;
  user-select: none !important;
  width: 100%;
}

.carousel__top {
  position: relative;

  .services__carousel__btn {
    outline: none;
    position: absolute;
    z-index: 1;
    width: 40px;
    height: 56px;
    background-color: rgba(1, 1, 1, 0.4);
    border: none;
    border-radius: 2px;
    top: 45%;
    transform: translate(0, -50%);
    color: white;
  }

  .services__carousel__btn:focus {
    background-color: rgba(1, 1, 1, 0.6);
  }

  .btn__left {
    left: 25%;
  }

  .btn__right {
    right: 25%;
  }

  .scroll__blocker {
    display: block;
    position: absolute;
    height: 100%;
    width: 25%;
    z-index: 2;
  }

  .blocker__left {
    left: 0;
  }

  .blocker__right {
    right: 0;
  }



  .carousel__images {
    height: 400px;

    li img {
      filter: brightness(0.5);
      transition: ease 0.4s;

    }


    .carousel_image__desktop {

    }
    .carousel_image__mobile {
      display: none;
    }

    .is-current img {
      filter: brightness(1);
    }

    .hooper-slide {
      img {
        object-fit: cover;
        width: 101%;
        height: 100%;
      }
    }
  }

  .carousel__text {
    height: 48px;
    background-color: $color-dark;
    color: white;

    .hooper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.carousel__bottom {
  position: relative;
  height: 400px;
  margin: 0 40px;

  .scroll__blocker {
    display: block;
    position: absolute;
    height: 100%;
    width: 300px;
    z-index: 2;
  }

  .blocker__left {
    left: 0;
  }

  .blocker__right {
    right: 0;
  }

  .hooper {
    height: 300px;

    .hooper-slide {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 50px;
    }

    img {
      width: 100%;
      object-fit: contain;
    }

    .hooper-progress {
      top: 100%;
      left: 40%;
      right: 40%;
      height: 2px;
      background-color: $color-black;

      .hooper-progress-inner {
        background-color: $color-brown;
      }
    }
  }
}

@media (max-width: 1200px) {
  .carousel__bottom {
    .hooper {
      .hooper-slide {
        padding: 25px;
      }
    }
  }
}

@media (max-width: 1000px) {
  .carousel__bottom {
    .scroll__blocker {
      width: 15%;
    }

    .hooper {
      height: 300px;

      .hooper-slide {
        padding: 10px;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: scale-down;
      }

      .hooper-progress {
        left: 25%;
        right: 25%;
      }
    }
  }
}

@media (max-width: 768px) {
  .services__carousel {
    .btn__left {
      left: 5%;
    }

    .btn__right {
      right: 5%;
    }

    .scroll__blocker {
      display: none;
    }
  }

    .carousel_image__desktop {
      display: none;
    }
    .carousel_image__mobile {
      display: block !important;
    }
}

@media (max-width: 500px) {
  .services__carousel {
    .carousel__top {
      height: 520px;
    }
  }
}

</style>