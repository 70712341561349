<template>
  <div class="services__content">
    <div class="content__upper__block">
      <div class="upper__block__left">
        <div class="upper__block__left__links">
          <p @click="setCurrentText(cafeText)" :class="(this.currentText === this.cafeText)?'active':'inactive'">
            {{ restaurant.value }}
          </p>
          <p @click="setCurrentText(hotelText)" :class="(this.currentText === this.hotelText)?'active':'inactive'">
          {{hotels.value}}
          </p>
        </div>
        <div class="upper__block__left__decoration">
          <div class="decoration__bar first"></div>
          <div class="decoration__bar second"></div>
          <div class="decoration__bar third"></div>
          <div class="decoration__bar fourth"></div>
        </div>
      </div>
      <div class="upper__block__right" v-html="currentText">
        <!-- {{currentText}} -->
      </div>
    </div>
    <services-carousel :carouselItems="carouselItems" :carouselMobItems="carouselMobItems" :brandSliderItems="brandSliderItems"></services-carousel>
  </div>
</template>

<script>
import ServicesCarousel from "@/components/services/ServicesCarousel";
import axios from "axios";
import base__url from "@/config";


export default {
  name: "ServicesContentMain",
  data() {
    return {
      carouselItems: [],
      carouselMobItems: [],
      brandSliderItems: [],
      currentText: null,
      responseData: {},
      cafeText: "",
      hotelText: "",
      restaurant: {},
      hotels: {},
    };
  },
  components: {
    ServicesCarousel,
  },
  async mounted() {
    this.setInitialText();
    axios.get('/api/partners/1').then(({ data }) => {
      this.responseData = data.partner
      this.cafeText = data.partner.main_text
      this.currentText = data.partner.main_text
      this.hotelText = data.partner.children[0].main_text
      this.brandSliderItems = data.partner.brands
      this.carouselItems = data.partner.presentations
      this.carouselMobItems = data.partner.presentations_mob
    });

    //  get restaurant text
    axios.get('/api/settings/partners.restaurants').then(response => (this.restaurant = response.data.setting))
    //  get restaurant text
    axios.get('/api/settings/partners.hotels').then(response => (this.hotels = response.data.setting))
  },
  computed: {},
  methods: {
    setInitialText() {
      this.currentText = this.cafeText
    },
    setCurrentText(setText) {
      if (this.currentText !== setText)
        this.currentText = setText
    }
  },
};
</script>

<style lang="scss">
@import "src/sass/variables";

.content__upper__block {
  padding: 0 40px;
  margin: 64px auto;
  display: flex;
  justify-content: space-between;

  .upper__block__left {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    width: 100%;

    .upper__block__left__links {
      cursor: pointer;
      font-family: $font-primary;
      font-size: 24px;
      line-height: 20px;
      letter-spacing: 0.12em;
      text-transform: uppercase;

      .active {
        color: $color-brown;
      }

      .inactive {
        color: $color-dark;
      }

      p {
        margin-bottom: 48px;
      }
    }

    .upper__block__left__decoration {
      margin-top: 96px;
      display: flex;
      justify-content: flex-end;
      width: 100%;
      height: 24px;
      background-color: $color-brown-easy;

      .decoration__bar {
        background-color: white;
        height: 100%;
        width: 10px;
      }

      .first {
        margin-right: 36px;
      }

      .second {
        margin-right: 24px;
      }

      .third {
        margin-right: 20px;
      }

      .fourth {
        margin-right: 10px;
      }
    }
  }

  .upper__block__right {
    margin-left: 32px;
    max-width: 760px;
    width: 100%;
    //font settings
    font-family: $font-secondary;
    font-size: 16px;
    line-height: 26px;
  }
}

@media (max-width: 1000px) {
  .content__upper__block {
    padding: 0 16px;
    flex-direction: column;

    .upper__block__left {
      .upper__block__left__decoration {
        margin: 32px auto;
      }
    }

    .upper__block__right {
      margin-left: 0;
    }
  }
}
</style>