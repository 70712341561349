<template>
  <div class="services__content">
    <div class="retail__content__description">
      <div class="description__block">
        <div class="description__block__text" v-html="responseData.main_text">
        </div>
        <div class="description__block__decoration">
          <div class="decoration__bar first"></div>
          <div class="decoration__bar second"></div>
          <div class="decoration__bar third"></div>
          <div class="decoration__bar fourth"></div>
        </div>
      </div>
      <div class="description__background">
        <img src="/images/services/retail-KWS.svg" alt=""/>
      </div>
    </div>
    <div class="retail__carousel">
      <services-carousel
          :carouselItems="carouselItems"
          :carouselMobItems="carouselMobItems"
          :brandSliderItems="brandSliderItems"
      ></services-carousel>
    </div>
  </div>
</template>

<script>
import ServicesCarousel from "@/components/services/ServicesCarousel";
import axios from "axios";

export default {
  name: "ServicesContentRetail",
  data() {
    return {
      responseData: {},
      carouselItems: [],
      carouselMobItems: [],
      brandSliderItems: [],
    };
  },
  components: {
    ServicesCarousel,
  },
  mounted() {
    axios.get('/api/partners/' + this.$route.params.id).then(({ data }) => {
      this.brandSliderItems = data.partner.brands
      this.carouselItems = data.partner.presentations
      this.carouselMobItems = data.partner.presentations_mob
      this.responseData = data.partner
    });
  }
};
</script>

<style lang="scss">
@import "src/sass/variables";

.services__content {
  .retail__content__description {
    padding: 0 40px;
    position: relative;
    max-height: 340px;
    height: 100%;
    display: flex;

    .description__block {
      position: relative;
      z-index: 2;
      margin: 5% 0;
      width: 50%;

      .description__block__decoration {
        margin-top: 54px;
        display: flex;
        justify-content: flex-end;
        width: 50%;
        height: 24px;
        background-color: $color-brown-easy;

        .decoration__bar {
          background-color: white;
          height: 100%;
          width: 10px;
        }

        .first {
          margin-right: 36px;
        }

        .second {
          margin-right: 24px;
        }

        .third {
          margin-right: 20px;
        }

        .fourth {
          margin-right: 10px;
        }
      }
    }

    .description__background {
      display: block;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 100%;

      img {
        height: 100%;
      }
    }
  }
}
.description__block__text {
  font-family: $font-secondary;
}
@media (max-width: 1000px) {
  .services__content {
    .retail__content__description {
      margin: 40px auto;
      max-height: unset;
      padding: 0 16px;

      .description__block {
        width: 100%;

        .description__block__decoration {
          width: 100%;
        }

      }

      .description__background {
        display: none;
      }
    }
  }
}
</style>